import React, { useState } from 'react';

//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import CategoryIcon from '@mui/icons-material/Category';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import useCategoryService from 'services/useCategoryService';
import { LoadingButton } from '@mui/lab';

const CategoriesForm = (props) => {
    //Constants
    const { addCategory, updateCategory } = useCategoryService();
    const { onSave, onCancel, selectedCategory } = props;

    //States
    const [loading, setLoading] = useState(false);

    //Methods
    const formik = useFormik({
        initialValues: {
            id: selectedCategory.id,
            name: selectedCategory.name,
            description: selectedCategory.description
        },
        validationSchema: Yup.object({
            name: Yup.string().max(50, 'Name must be 50 characters or less').required('Name is required'),
            description: Yup.string()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedCategory.id != 0) {
                updateCategory(selectedCategory.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                addCategory(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedCategory.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <CategoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Category</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <CategoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Category Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                fullWidth
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                label="Description"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                fullWidth
                                multiline
                                rows={3}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default CategoriesForm;
