//import React from 'react';

import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useProductService = () => {
    // const getProductByName = async (name, startRate, endRate, categoryId) => {
    //     try {
    //         const response = await axiosAuth.get(
    //             `/api/Products?Name=${name}&StartRate=${startRate}&EndRate=${endRate}&CategoryId=${categoryId}`
    //         );
    //         return handleResponse(response);
    //     } catch (error) {
    //         return handleError(error);
    //     }
    // };

    const searchProduct = async (skip, take, name, startRate, endRate, categoryId) => {
        try {
            const response = await axiosAuth.get(
                `/api/Products?skip=${skip}&take=${take}&Name=${name}&StartRate=${startRate}&EndRate=${endRate}&CategoryId=${categoryId}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getProduct = async (skip, take) => {
        try {
            const response = await axiosAuth.get(`/api/Products?skip=${skip}&take=${take}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addProduct = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Products`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateProduct = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Products?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteProduct = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Products/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        addProduct,
        updateProduct,
        softDeleteProduct,
        //getProductByName,
        searchProduct,
        getProduct
    };
};
export default useProductService;
