import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useReportService = () => {
    const getRevenueTotalReport = async (role, location, fromDate, toDate) => {
        try {
            const response = await axiosAuth.get(
                `/api/Reports/revenue-total-report?role=${role}&location=${location}&fromDate=${fromDate}&toDate=${toDate}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getRevenueDetailsReport = async (userId, fromDate, toDate) => {
        try {
            const response = await axiosAuth.get(
                `/api/Reports/revenue-Detail-report?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getRevenueTotalReport,
        getRevenueDetailsReport
    };
};

export default useReportService;
