import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useTMDailyAllowanceService = () => {
    const getTMDailyAllowance = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/TMDAs/by-tm-salaryslab-id?tmSalarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addTMDailyAllowance = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/TMDAs`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateTMDailyAllowance = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/TMDAs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteTMDailyAllowance = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/TMDAs/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getTMDailyAllowance, addTMDailyAllowance, updateTMDailyAllowance, softDeleteTMDailyAllowance };
};

export default useTMDailyAllowanceService;
