import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    CircularProgress,
    Select,
    MenuItem,
    Tabs,
    Tab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';

//icons
import PaymentIcon from '@mui/icons-material/Payment';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import PaymentsIcon from '@mui/icons-material/Payments';

//project imports
import { locations, roles } from 'store/constant';

import NoResults from 'assets/images/noresults.png';
import useSalariesService from 'services/useSalariesService';
import useEmployeeService from 'services/useEmployeeService';
import useAmountTransactionService from 'services/useAmountTransactionService';

import AmountPaid from './AmountPaid';

const AmountTransaction = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { getAmountTransactionFilter, updateAmountTransactionStatus } = useAmountTransactionService();
    const { getEmployees } = useEmployeeService();
    //const { month, year } = props;
    const pageSize = 50;

    const { userId } = useSelector((state) => state.userReducer);
    //States
    const [amountDetails, setAmountDetails] = useState([]);
    const [employeeData, setEmployeeData] = useState();

    //const date = new Date();
    const [selectedSender, setSelectedSender] = useState(0);
    const [selectedReceiver, setSelectedReceiver] = useState(0);
    const [selectedFromDate, setSelectedFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedToDate, setSelectedToDate] = useState(new Date().toISOString().split('T')[0]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [isError, setIsError] = useState();
    const [tabValue, setTabValue] = useState(0);
    const [currentSkipValue, setCurrentSkipValue] = useState(0);

    const handleAccept = (transactionId, status) => {
        updateAmountTransactionStatus(transactionId, status)
            .then((response) => {
                console.log(response);
                getAmountReceivedList();
                //setAccepted(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //Methods
    const handleRefreshClick = () => {
        getAmountReceivedList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const getEmployeeList = () => {
        getEmployees(-1, -1, currentSkipValue, pageSize)
            .then((response) => {
                console.log(response);

                setEmployeeData(response.data.users);
            })
            .catch((error) => setIsError(error.message));
    };

    const getAmountReceivedList = () => {
        //setEmployeeSalaryDetails(undefined);
        // console.log('userId', selectedSender, userId, selectedFromDate, selectedToDate);
        getAmountTransactionFilter(selectedSender, userId, selectedFromDate, selectedToDate, -1)
            .then((response) => {
                //console.log(selectedSender, 3, selectedFromDate, selectedToDate);
                console.log(response);
                //setEmployeeCount(response.data.count);
                // console.log('DRRRR', response.data, selectedRole, selectedMonth, selectedYear);
                setAmountDetails(response.data.amountTransactions);
            })
            .catch((error) => setIsError(error.message));
    };

    // const handleDelete = () => {
    //     deleteSalaries(selectedEmployeeSalaryRecord.id)
    //         .then((response) => {
    //             getEmployeeList();
    //             setOpenToast(true);
    //             setOpenDeleteDialog(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setOpenDeleteDialog(false);
    //         });
    // };

    // const handleChange = (e) => {
    //     setSelectedLocation(e.target.value);
    // };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    //useEffects
    useEffect(() => {
        getAmountReceivedList();
    }, [selectedSender, userId, selectedFromDate, selectedToDate]);
    useEffect(() => {
        getEmployeeList();
    }, []);
    return (
        <>
            <MainCard>
                <CardHeader avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} title="Amount Transactions" />
                <Divider sx={{ marginY: 3 }} />
                <Tabs alignItems="left" sx={{ mb: 2 }} value={tabValue} onChange={handleTabChange}>
                    <Tab align="left" label="Amount Received"></Tab>
                    <Tab label="Amount Paid"></Tab>
                </Tabs>{' '}
                {tabValue === 1 && (
                    <Grid item sm={12} xs={12}>
                        <AmountPaid />
                    </Grid>
                )}
                {/* {tabValue === 0 && (
                    <Grid item sm={12} xs={12}>
                        <AmountReceived />
                    </Grid>
                )} */}
                {tabValue === 0 && (
                    <>
                        <Typography variant="h4">Select parameters to view amount received</Typography>
                        <Grid container direction="row" spacing={2} alignItems="center" sx={{ marginY: 0.5 }}>
                            {employeeData != undefined || employeeData != null ? (
                                <Grid item sm={4} xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        disablePortal
                                        id="employee-list-autocomplete"
                                        getOptionLabel={(employeeData) =>
                                            `${employeeData.firstName} ${employeeData.middleName} ${employeeData.lastName}`
                                        }
                                        options={employeeData}
                                        isOptionEqualToValue={(option, value) => {
                                            option.firstName === value.firstName,
                                                option.middleName === value.middleName,
                                                option.lastName === value.lastName;
                                        }}
                                        noOptionsText={'No Item Found'}
                                        onChange={(event, value) => {
                                            setSelectedSender(value.id);
                                        }}
                                        renderOption={(props, employeeData) => (
                                            <Box component="li" {...props} key={employeeData.id}>
                                                {`${employeeData.firstName} ${employeeData.middleName} ${employeeData.lastName}`}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField label="Received From" size="small" fullWidth {...params} />}
                                    />
                                </Grid>
                            ) : (
                                <Typography variant="h6">Getting List</Typography>
                            )}

                            <Grid item sm={3} xs={12}>
                                <TextField
                                    type="date"
                                    label="From"
                                    size="small"
                                    fullWidth
                                    //sx={{ width: 150 }}
                                    value={selectedFromDate}
                                    onChange={(e) => {
                                        setSelectedFromDate(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    label="To"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    //sx={{ width: 150 }}
                                    value={selectedToDate}
                                    onChange={(e) => {
                                        setSelectedToDate(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        handleRefreshClick();
                                    }}
                                >
                                    {/* <RefreshIcon fontSize="small" /> */}
                                    Refresh
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider sx={{ marginY: 3 }} />
                        {amountDetails != undefined || amountDetails != null ? (
                            <>
                                {amountDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no amount received record found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 70 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        {/* <TableCell>
                                                <Typography variant="h5">Sender Name</Typography>{' '}
                                            </TableCell> */}
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Receiver Name</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 120 }}>
                                                            <Typography variant="h5">Date</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 120 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 120 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {amountDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                {/* <TableCell align="left">{row.senderName}</TableCell> */}
                                                                <TableCell align="left">{row.receiverName}</TableCell>
                                                                <TableCell align="left">
                                                                    {new Date(row.date).toLocaleDateString('hi-IN')}
                                                                </TableCell>
                                                                <TableCell align="left">{row.amount}</TableCell>
                                                                <TableCell align="right">
                                                                    <Tooltip title="Accept">
                                                                        <Button
                                                                            variant="contained"
                                                                            color={row.status == 0 ? 'primary' : 'secondary'}
                                                                            onClick={() => {
                                                                                row.status == 0
                                                                                    ? handleAccept(row.id, 1)
                                                                                    : handleAccept(row.id, 0);
                                                                            }}
                                                                        >
                                                                            {row.status == 0 ? 'Accept' : 'Accepted'}
                                                                        </Button>
                                                                    </Tooltip>

                                                                    {/* <Tooltip title="Delete employee salary record">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedEmployeeSalaryRecord(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>*/}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {amountDetails?.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Sender Name:</Typography>
                                                        <Typography>{row.senderName}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Receiver Name:</Typography>
                                                        <Typography> {row.receiverName}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Date:</Typography>
                                                        <Typography>{new Date(row.date).toLocaleDateString('hi-IN')}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>

                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Sales details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    const state = {
                                                                        userId: row.userId,
                                                                        fromDate: selectedFromDate,
                                                                        toDate: selectedToDate
                                                                    };
                                                                    navigate('/revenuereportdetails', { state });
                                                                }}
                                                            >
                                                                <ArticleIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/* <Tooltip title="Delete employee salary record">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedEmployeeSalaryRecord(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                <Typography variant="h4" textAlign="center">
                                    Getting Amount Received List...
                                </Typography>
                                <CircularProgress />
                            </Stack>
                        )}
                    </>
                )}
            </MainCard>
        </>
    );
};

export default AmountTransaction;
