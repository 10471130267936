import React, { useState, useEffect } from 'react';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SalaryReadOnly = (props) => {
    const { title, description, value } = props;
    const theme = useTheme();
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="caption">{description}</Typography>
            </Stack>
            <Typography variant="h5">
                ₹{' '}
                {Number(value).toLocaleString('en-IN', {
                    maximumFractionDigits: 2
                })}
            </Typography>
        </Stack>
    );
};

export default SalaryReadOnly;
