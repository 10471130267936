import React, { useEffect, useState } from 'react';
//Material Imports
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import NoResults from 'assets/images/noresults.png';
import GLGroupIncentive from './GLGroupIncentive';
import GLSalarySlabItems from './GLSalarySlabItems';
import useGLSalarySlabsService from 'services/useGLSalarySlabsService';
import useGLSalarySlabItemsService from 'services/useGLSalarySlabItemsService';
import useGLGroupIncentivesService from 'services/useGLGroupIncentivesService';

const GLSalarySlab = () => {
    //Constants
    const theme = useTheme();
    const { getGLSalarySlab, updateGLSalarySlab } = useGLSalarySlabsService();
    const { getGLSalarySlabItems, softDeleteGLSalarySlabItems } = useGLSalarySlabItemsService();
    const { getGLGroupIncentives, softDeleteGLGroupIncentives } = useGLGroupIncentivesService();

    //States
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingGLSalarySlab, setLoadingGLSalarySlab] = useState(false);
    const [glSalarySlabDetails, setGLSalarySlabDetails] = useState([]);
    const [glSalarySlabItemsDetails, setGLSalarySlabItemsDetails] = useState([]);
    const [glGroupIncentiveDetails, setGLGroupIncentiveDetails] = useState([]);

    const [selectedGLSalarySlabItems, setSelectedGLSalarySlabItems] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [selectedGLGroupIncentive, setSelectedGLGroupIncentive] = useState('');
    const [openFormGLGroupIncentive, setOpenFormGLGroupIncentive] = useState(false);
    const [openGLGroupIncentiveDeleteDialog, setOpenGLGroupIncentiveDeleteDialog] = useState(false);
    const [openGLGroupIncentiveToast, setGLGroupIncentiveOpenToast] = useState(false);

    const [glFixedSalary, setGLFixedSalary] = useState('');

    const [glSalarySlabId, setGLSalarySlabId] = useState(0);

    // Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getGLSalarySlabItemsList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const handleClose = () => {
        setOpenFormGLGroupIncentive(false);
    };

    const handleSaveGLGroupIncentive = () => {
        getGLGroupIncentiveList();
    };

    const handleCloseGLGroupIncentiveToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setGLGroupIncentiveOpenToast(false);
    };
    //GL Salary Slab
    const getGLSalarySlabList = () => {
        setGLSalarySlabDetails(undefined);
        getGLSalarySlab()
            .then((response) => {
                console.log(response);
                setGLSalarySlabDetails(response.data.list);
                setGLFixedSalary(response.data.list[0].fixedSalary);
                setGLSalarySlabId(response.data.list[0].id);
            })
            .catch((error) => setIsError(error.message));
    };
    //GL Field Expenses
    const getGLSalarySlabItemsList = () => {
        setGLSalarySlabItemsDetails(undefined);
        getGLSalarySlabItems(glSalarySlabId)
            .then((response) => {
                console.log(response);
                setGLSalarySlabItemsDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleDelete = () => {
        setLoading(true);
        softDeleteGLSalarySlabItems(selectedGLSalarySlabItems.id)
            .then((response) => {
                getGLSalarySlabItemsList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };
    //GL Group Incentive
    const getGLGroupIncentiveList = () => {
        setGLGroupIncentiveDetails(undefined);
        getGLGroupIncentives(glSalarySlabId)
            .then((response) => {
                console.log(response);
                setGLGroupIncentiveDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleGLGroupIncentiveDelete = () => {
        setLoading(true);
        softDeleteGLGroupIncentives(selectedGLGroupIncentive.id)
            .then((response) => {
                getGLGroupIncentiveList();
                setGLGroupIncentiveOpenToast(true);
                setOpenGLGroupIncentiveDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenGLGroupIncentiveDeleteDialog(false);
            });
    };

    //Update GL Salary
    const handleUpdate = () => {
        setLoadingGLSalarySlab(true);
        let data = {
            fixedSalary: glFixedSalary
        };
        updateGLSalarySlab(glSalarySlabDetails[0].id, data).then((response) => {
            setLoadingGLSalarySlab(false);
            console.log('UPDATE CALL', response);
        });
    };

    //UseEffects
    useEffect(() => {
        getGLSalarySlabList();
        // getGLSalarySlabItemsList();
        // getGLGroupIncentiveList();
    }, []);
    useEffect(() => {
        getGLSalarySlabItemsList();
    }, [glSalarySlabId]);

    useEffect(() => {
        getGLGroupIncentiveList();
    }, [glSalarySlabId]);

    return (
        <>
            <MainCard>
                <CardHeader title="GL Salary Slabs" avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Grid item sm={2} xs={12}>
                        <Typography variant="h5">Fixed Salary :</Typography>
                    </Grid>
                    {glFixedSalary != undefined || glFixedSalary != null ? (
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="Fixed Salary"
                                type="number"
                                name="glFixedSalary"
                                label="Fixed Salary"
                                variant="outlined"
                                value={glFixedSalary}
                                fullWidth
                                onChange={(event) => {
                                    setGLFixedSalary(event.target.value);
                                    //console.log(abmFixeSalary);
                                }}
                            ></TextField>
                        </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12}>
                        <LoadingButton
                            loading={loadingGLSalarySlab}
                            onClick={() => {
                                handleUpdate();
                            }}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">GL Field Expenses</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedGLSalarySlabItems({
                                    id: 0,
                                    glSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    fieldExpenses: 0,
                                    pri: 0,
                                    da: 0,
                                    ta: 0,
                                    incentive: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {glSalarySlabItemsDetails != undefined || glSalarySlabItemsDetails != null ? (
                            <>
                                {glSalarySlabItemsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no GL salary slab item details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Units</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 180 }}>
                                                            <Typography variant="h5">Field Expenses</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 180 }}>
                                                            <Typography variant="h5">PRI</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">D.A / Day</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">T.A / Day</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">Incentive</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {glSalarySlabItemsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>
                                                                {/* <TableCell align="left">{row.unitMax}</TableCell> */}
                                                                <TableCell align="left">{row.fieldExpenses}</TableCell>
                                                                <TableCell align="left">{row.pri}</TableCell>
                                                                <TableCell align="left">{row.da}</TableCell>
                                                                <TableCell align="left">{row.ta}</TableCell>
                                                                <TableCell align="left">{row.incentive}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit GL salary slab item details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGLSalarySlabItems(row);
                                                                                setOpenForm(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete GL salary slab item">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGLSalarySlabItems(row);
                                                                                setOpenDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {glSalarySlabItemsDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Field Expenses:</Typography>
                                                        <Typography>{row.fieldExpenses}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">PRI:</Typography>
                                                        <Typography>{row.pri}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">D.A:</Typography>
                                                        <Typography>{row.da}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">T.A:</Typography>
                                                        <Typography>{row.ta}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Incentive:</Typography>
                                                        <Typography>{row.incentive}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit GL salary slab item details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGLSalarySlabItems(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete GL salary slab item">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGLSalarySlabItems(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">GL Group Incentive</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedGLGroupIncentive({
                                    id: 0,
                                    gLSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    amount: 0
                                });
                                setOpenFormGLGroupIncentive(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {glGroupIncentiveDetails != undefined || glGroupIncentiveDetails != null ? (
                            <>
                                {glGroupIncentiveDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no GL group incentive details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Unit</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 120 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {glGroupIncentiveDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>
                                                                {/* <TableCell align="left">{row.unitMax}</TableCell> */}
                                                                <TableCell align="left">{row.amount}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit GL group incentive details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGLGroupIncentive(row);
                                                                                setOpenFormGLGroupIncentive(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete GL group incentive">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGLGroupIncentive(row);
                                                                                setOpenGLGroupIncentiveDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {glGroupIncentiveDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit GL group incentive details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGLGroupIncentive(row);
                                                                    setOpenFormGLGroupIncentive(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete GL group incentive">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGLGroupIncentive(row);
                                                                    setOpenGLGroupIncentiveDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
            </MainCard>

            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <GLSalarySlabItems onSave={handleSave} onCancel={handleCancel} selectedGLSalarySlabItems={selectedGLSalarySlabItems} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete GL Salary Slab Item Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this GL salary slab item details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> GL salary slab item deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ---------------GL GroupIncentive----------------------------- */}
            <Dialog open={openFormGLGroupIncentive} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogContent>
                    <GLGroupIncentive
                        onSave={handleSaveGLGroupIncentive}
                        onCancel={handleClose}
                        selectedGLGroupIncentive={selectedGLGroupIncentive}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openGLGroupIncentiveDeleteDialog}
                onClose={() => {
                    setOpenGLGroupIncentiveDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete GL Group Incentive Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this GL group incentive details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleGLGroupIncentiveDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenGLGroupIncentiveDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openGLGroupIncentiveToast} autoHideDuration={6000} onClose={handleCloseGLGroupIncentiveToast}>
                    <Alert
                        onClose={handleCloseGLGroupIncentiveToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> GL group incentive deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default GLSalarySlab;
