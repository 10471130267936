import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useGCSalarySlabsService = () => {
    const getGCSalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/GCSalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getGCSalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/GCSalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateGCSalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/GCSalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { getGCSalarySlab, getGCSalarySlabById, updateGCSalarySlab };
};

export default useGCSalarySlabsService;
