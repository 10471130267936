import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useABMSalarySlabsService = () => {
    const getABMSalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/ABMSalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getABMSalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/ABMSalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateABMSalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/ABMSalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { getABMSalarySlab, getABMSalarySlabById, updateABMSalarySlab };
};

export default useABMSalarySlabsService;
