import React, { useState, useEffect } from 'react';

//MaterialUI Imports
import {
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Stack,
    Alert,
    Snackbar,
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardActions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryIcon from '@mui/icons-material/Category';

//import axios from 'axios';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import CategoriesForm from './CategoriesForm';
import useCategoryService from 'services/useCategoryService';
import NoResults from 'assets/images/noresults.png';

const Categories = () => {
    //Constants
    const theme = useTheme();
    const { getCategory, softDeleteCategory } = useCategoryService();

    //States

    const [categoryDetails, setCategoryDetails] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [isError, setIsError] = useState();

    // const [categoryCount, setCategoryCount] = useState();
    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentSkipValue, setCurrentSkipValue] = useState(0);

    //Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getCategoryList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getCategoryList = () => {
        setCategoryDetails(undefined);
        getCategory()
            .then((response) => {
                //console.log(response);
                //setCategoryCount(response.data.counts);
                setCategoryDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    useEffect(() => {
        getCategoryList();
    }, []);

    const handleDelete = () => {
        softDeleteCategory(selectedCategory.id)
            .then((response) => {
                getCategoryList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<CategoryIcon color="primary" />}
                    title="Category List"
                    sx={{ padding: 0 }}
                    action={
                        <Grid container>
                            <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    align="right"
                                    onClick={() => {
                                        setSelectedCategory({
                                            id: 0,
                                            name: '',
                                            description: ''
                                        });
                                        setOpenForm(true);
                                    }}
                                >
                                    Add New Category
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />

                <Divider sx={{ marginY: 3 }} />
                {/* {categoryDetails != undefined && (
                    <>
                        {categoryCount != undefined && categoryCount != 0 ? (
                            <Pagination
                                count={Math.ceil(categoryCount / pageSize)}
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                color="primary"
                                sx={{ marginY: 2 }}
                                onChange={(event, page) => {
                                    setCurrentSkipValue((page - 1) * pageSize);
                                    setCurrentPage(page);
                                }}
                            />
                        ) : null}
                    </>
                )} */}
                <Grid container spacing={2}>
                    <Grid item xs={12} align="right" sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedCategory({
                                    id: 0,
                                    name: '',
                                    description: ''
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add New Category
                        </Button>
                    </Grid>
                    {categoryDetails != undefined || categoryDetails != null ? (
                        <>
                            {categoryDetails?.length === 0 ? (
                                <Grid item sm={12} align="center" sx={{ mt: 5, mb: 5 }}>
                                    <Stack justifyContent="center" alignItems="center">
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no category details found
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ) : (
                                <>
                                    {' '}
                                    <Grid
                                        item
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                    >
                                        <TableContainer>
                                            <Table
                                                sx={{
                                                    border: 'solid',
                                                    borderWidth: 1,
                                                    borderColor: 'divider'
                                                }}
                                            >
                                                <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                    <TableCell sx={{ width: 90 }}>
                                                        <Typography variant="h5">Sr. No.</Typography>{' '}
                                                    </TableCell>
                                                    <TableCell sx={{ width: 300 }}>
                                                        <Typography variant="h5">Name</Typography>{' '}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h5">Description</Typography>
                                                    </TableCell>

                                                    <TableCell sx={{ width: 120 }}></TableCell>
                                                </TableHead>
                                                <TableBody>
                                                    {categoryDetails?.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.description}</TableCell>

                                                            <TableCell align="right">
                                                                <Tooltip title="Edit Category details">
                                                                    <IconButton
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSelectedCategory(row);
                                                                            setOpenForm(true);
                                                                        }}
                                                                    >
                                                                        <EditIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Category">
                                                                    <IconButton
                                                                        color="error"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSelectedCategory(row);
                                                                            setOpenDeleteDialog(true);
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    {categoryDetails.map((row, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                        >
                                            <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                    <Typography variant="h5">Name:</Typography>
                                                    <Typography>{row.name}</Typography>
                                                </Stack>
                                                <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                    <Typography variant="h5">Description:</Typography>
                                                    <Typography>{row.description}</Typography>
                                                </Stack>
                                                <Divider sx={{ mt: 1 }} />
                                                <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                    <Tooltip title="Edit Category details">
                                                        <IconButton
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                setSelectedCategory(row);
                                                                setOpenForm(true);
                                                            }}
                                                        >
                                                            <EditIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Category">
                                                        <IconButton
                                                            color="error"
                                                            size="small"
                                                            onClick={() => {
                                                                setSelectedCategory(row);
                                                                setOpenDeleteDialog(true);
                                                            }}
                                                        >
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </Card>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                            <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                <Typography variant="h4" textAlign="center">
                                    Getting Category List...
                                </Typography>
                                <CircularProgress />
                            </Stack>
                        </Grid>
                    )}
                </Grid>

                {/* </Grid> */}

                {/* </Grid> */}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <CategoriesForm onSave={handleSave} onCancel={handleCancel} selectedCategory={selectedCategory} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Category Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this category details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> Category deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default Categories;
