import React, { useEffect, useState } from 'react';

//Material Imports
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import NoResults from 'assets/images/noresults.png';
import TMFieldExpenses from './TMFieldExpenses';
import TMPRIs from './TMPRIs';
import useTMSalarySlabsService from 'services/useTMSalarySlabsService';
import useTMFieldExpensesService from 'services/useTMFieldExpensesService';
import useTMPRIsService from 'services/useTMPRIsService';
import useTMDailyAllowanceService from 'services/useTMDailyAllowanceService';
import TMDailyAllowance from './TMDailyAllowance';

const TMSalarySlab = () => {
    //Constants
    const theme = useTheme();
    const { getTMSalarySlab, updateTMSalarySlab } = useTMSalarySlabsService();
    const { getTMFieldExpenses, softDeleteTMFieldExpenses } = useTMFieldExpensesService();
    const { getTMPRIs, softDeleteTMPRIs } = useTMPRIsService();
    const { getTMDailyAllowance, softDeleteTMDailyAllowance } = useTMDailyAllowanceService();

    //States
    const [loading, setLoading] = useState(false);
    const [loadingTMSalarySlab, setLoadingTMSalarySlab] = useState(false);
    const [isError, setIsError] = useState();
    const [tmSalarySlabDetails, setTMSalarySlabDetails] = useState([]);
    const [tmDAsDetails, setTMDAsDetails] = useState([]);
    const [tmFieldExpensesDetails, setTMFieldExpensesDetails] = useState([]);
    const [tmPRIsDetails, setTMPRIsDetails] = useState([]);

    const [selectedTMFieldExpenses, setSelectedTMFieldExpenses] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [selectedTMPRIs, setSelectedTMPRIs] = useState('');
    const [openFormTMPRIs, setOpenFormTMPRIs] = useState(false);
    const [openTMPRIsDeleteDialog, setOpenTMPRIsDeleteDialog] = useState(false);
    const [openTMPRIsToast, setTMPRIsOpenToast] = useState(false);

    const [selectedTMDAs, setSelectedTMDAs] = useState('');
    const [openFormTMDAs, setOpenFormTMDAs] = useState(false);
    const [openTMDAsDeleteDialog, setOpenTMDAsDeleteDialog] = useState(false);
    const [openTMDAsToast, setTMDAsOpenToast] = useState(false);

    const [tmFixedSalary, setTMFixedSalary] = useState('');
    const [tmSalarySlabId, setTMSalarySlabId] = useState(0);

    // Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getTMFieldExpensesList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    //TM PRIs
    const handleClose = () => {
        setOpenFormTMPRIs(false);
    };

    const handleSaveTMPRIs = () => {
        getTMPRIsList();
    };
    const handleCloseTMPRIsToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setTMPRIsOpenToast(false);
    };

    //TM DAs
    const handleCloseTMDAs = () => {
        setOpenFormTMDAs(false);
    };

    const handleSaveTMDAs = () => {
        getTMDAsList();
    };

    const handleCloseTMDAsToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setTMDAsOpenToast(false);
    };

    //TM Salary Slab
    const getTMSalarySlabList = () => {
        setTMSalarySlabDetails(undefined);
        getTMSalarySlab()
            .then((response) => {
                //console.log(response.data.list);
                setTMSalarySlabDetails(response.data.list);
                setTMFixedSalary(response.data.list[0].fixedSalary);
                setTMSalarySlabId(response.data.list[0].id);
            })
            .catch((error) => setIsError(error.message));
    };
    //TM Field Expenses
    const getTMFieldExpensesList = () => {
        setTMFieldExpensesDetails(undefined);
        getTMFieldExpenses(tmSalarySlabId)
            .then((response) => {
                console.log(response);
                setTMFieldExpensesDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleDelete = () => {
        setLoading(true);
        softDeleteTMFieldExpenses(selectedTMFieldExpenses.id)
            .then((response) => {
                getTMFieldExpensesList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };
    //TM PRIs
    const getTMPRIsList = () => {
        setTMPRIsDetails(undefined);
        getTMPRIs(tmSalarySlabId)
            .then((response) => {
                console.log(response);
                setTMPRIsDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleTMPRIsDelete = () => {
        setLoading(true);
        softDeleteTMPRIs(selectedTMPRIs.id)
            .then((response) => {
                getTMPRIsList();
                setTMPRIsOpenToast(true);
                setOpenTMPRIsDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenTMPRIsDeleteDialog(false);
            });
    };

    //TM DAs
    const getTMDAsList = () => {
        //setTMDAsDetails(undefined);
        getTMDailyAllowance(tmSalarySlabId)
            .then((response) => {
                console.log(response);
                setTMDAsDetails(response.data.tmdAs);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleTMDAsDelete = () => {
        setLoading(true);
        softDeleteTMDailyAllowance(selectedTMDAs.id)
            .then((response) => {
                getTMDAsList();
                setTMDAsOpenToast(true);
                setOpenTMDAsDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenTMDAsDeleteDialog(false);
            });
    };

    //Update TM Salary
    const handleUpdate = () => {
        setLoadingTMSalarySlab(true);
        let data = {
            fixedSalary: tmFixedSalary
        };
        //console.log(tmSalarySlabDetails);
        if (tmSalarySlabDetails != undefined || tmSalarySlabDetails != null) {
            updateTMSalarySlab(tmSalarySlabDetails[0].id, data).then((response) => {
                setLoadingTMSalarySlab(false);
                console.log('UPDATE CALL', response);
            });
        }
    };

    //UseEffects
    useEffect(() => {
        getTMSalarySlabList();
        // getTMFieldExpensesList();
        // getTMPRIsList();
    }, []);
    useEffect(() => {
        getTMFieldExpensesList();
    }, [tmSalarySlabId]);

    useEffect(() => {
        getTMPRIsList();
    }, [tmSalarySlabId]);

    useEffect(() => {
        getTMDAsList();
    }, [tmSalarySlabId]);

    return (
        <>
            <MainCard>
                <CardHeader title="TM Salary Slabs" avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Grid item sm={2} xs={12}>
                        <Typography variant="h5">Fixed Salary :</Typography>
                    </Grid>
                    {tmFixedSalary != undefined || tmFixedSalary != null ? (
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="Fixed Salary"
                                type="number"
                                name="tmFixeSalary"
                                label="Fixed Salary"
                                variant="outlined"
                                value={tmFixedSalary}
                                fullWidth
                                onChange={(event) => {
                                    setTMFixedSalary(event.target.value);
                                    //console.log(tmFixedSalary);
                                }}
                            ></TextField>
                        </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12}>
                        <LoadingButton
                            loading={loadingTMSalarySlab}
                            onClick={() => {
                                handleUpdate();
                            }}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">TM DAs</Typography>{' '}
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedTMDAs({
                                    id: 0,
                                    tmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    amount: 0,
                                    campToCamp: 0
                                });
                                setOpenFormTMDAs(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {tmDAsDetails != undefined || tmDAsDetails != null ? (
                            <>
                                {tmDAsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no TM DAs details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 70 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Unit</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Camp To Camp</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tmDAsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.amount}</TableCell>
                                                                <TableCell align="left">{row.campToCamp}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit TM DAs details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMDAs(row);
                                                                                setOpenFormTMDAs(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete TM DAs">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMDAs(row);
                                                                                setOpenTMDAsDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {tmDAsDetails?.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Camp To Camp:</Typography>
                                                        <Typography>{row.campToCamp}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit TM DAs details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMDAs(row);
                                                                    setOpenFormTMDAs(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete TM DAs">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMDAs(row);
                                                                    setOpenTMDAsDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">TM Field Expenses</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedTMFieldExpenses({
                                    id: 0,
                                    tmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    fieldExpenses: 0,
                                    petrol: 0,
                                    incentive: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {tmFieldExpensesDetails != undefined || tmFieldExpensesDetails != null ? (
                            <>
                                {tmFieldExpensesDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no TM field expenses details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 70 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Unit</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Petrol(LTRS)</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Incentive</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tmFieldExpensesDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.fieldExpenses}</TableCell>
                                                                <TableCell align="left">{row.petrol}</TableCell>
                                                                <TableCell align="left">{row.incentive}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit TM Field Expenses details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMFieldExpenses(row);
                                                                                setOpenForm(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete TM Field Expenses">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMFieldExpenses(row);
                                                                                setOpenDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {tmFieldExpensesDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.fieldExpenses}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Petrol:</Typography>
                                                        <Typography>{row.petrol}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Incentive:</Typography>
                                                        <Typography>{row.incentive}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit TM field xxpenses details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMFieldExpenses(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete TM field expenses">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMFieldExpenses(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">TM PRIs</Typography>{' '}
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedTMPRIs({
                                    id: 0,
                                    tmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    pri: 0
                                });
                                setOpenFormTMPRIs(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {tmPRIsDetails != undefined || tmPRIsDetails != null ? (
                            <>
                                {tmPRIsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no TM pri details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 70 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Unit</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tmPRIsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.pri}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit TM PRIs details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMPRIs(row);
                                                                                setOpenFormTMPRIs(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete TM PRIs">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedTMPRIs(row);
                                                                                setOpenTMPRIsDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {tmPRIsDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">PRIs:</Typography>
                                                        <Typography>{row.pri}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit TM PRIs details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMPRIs(row);
                                                                    setOpenFormTMPRIs(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete TM PRIs">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTMPRIs(row);
                                                                    setOpenTMPRIsDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <TMFieldExpenses onSave={handleSave} onCancel={handleCancel} selectedTMFieldExpenses={selectedTMFieldExpenses} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete TM Field Expenses Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this TM field expenses details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> TM field expenses deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ---------------TM PRIs----------------------------- */}
            <Dialog open={openFormTMPRIs} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogContent>
                    <TMPRIs onSave={handleSaveTMPRIs} onCancel={handleClose} selectedTMPRIs={selectedTMPRIs} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openTMPRIsDeleteDialog}
                onClose={() => {
                    setOpenTMPRIsDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete TM PRIs Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this TM PRIs details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleTMPRIsDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenTMPRIsDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openTMPRIsToast} autoHideDuration={6000} onClose={handleCloseTMPRIsToast}>
                    <Alert
                        onClose={handleCloseTMPRIsToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> TM PRIs deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ---------------TM DAs----------------------------- */}
            <Dialog open={openFormTMDAs} onClose={handleCloseTMDAs} fullWidth maxWidth="sm">
                <DialogContent>
                    <TMDailyAllowance onSave={handleSaveTMDAs} onCancel={handleCloseTMDAs} selectedTMDAs={selectedTMDAs} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openTMDAsDeleteDialog}
                onClose={() => {
                    setOpenTMDAsDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete TM DAs Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this TM DAs details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleTMDAsDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenTMDAsDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openTMDAsToast} autoHideDuration={6000} onClose={handleCloseTMDAsToast}>
                    <Alert
                        onClose={handleCloseTMDAsToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> TM DAs deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default TMSalarySlab;
