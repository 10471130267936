import React, { useState } from 'react';
//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import MoneyIcon from '@mui/icons-material/Money';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import useGCSalarySlabItemsService from 'services/useGCSalarySlabItemsService';

const GCSalarySlabItems = (props) => {
    //Constants
    const { onSave, onCancel, selectedGCSalarySlabItems } = props;
    const { addGCSalarySlabItems, updateGCSalarySlabItems } = useGCSalarySlabItemsService();

    //States
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: selectedGCSalarySlabItems.id,
            gcSalarySlabId: 1,
            unitMin: selectedGCSalarySlabItems.unitMin,
            unitMax: selectedGCSalarySlabItems.unitMax,
            fieldExpenses: selectedGCSalarySlabItems.fieldExpenses,
            incentive: selectedGCSalarySlabItems.incentive,
            pri: selectedGCSalarySlabItems.pri,
            da: selectedGCSalarySlabItems.da,
            ta: selectedGCSalarySlabItems.ta
        },
        validationSchema: Yup.object({
            unitMin: Yup.number(),
            unitMax: Yup.number(),
            fieldExpenses: Yup.number(),
            incentive: Yup.number(),
            pri: Yup.number(),
            da: Yup.number(),
            ta: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedGCSalarySlabItems.id != 0) {
                let data = {
                    gcSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses,
                    pri: values.pri,
                    da: values.da,
                    ta: values.ta,
                    incentive: values.incentive
                };
                updateGCSalarySlabItems(selectedGCSalarySlabItems.id, data)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                let data = {
                    gcSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses,
                    pri: values.pri,
                    da: values.da,
                    ta: values.ta,
                    incentive: values.incentive
                };
                addGCSalarySlabItems(data)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedGCSalarySlabItems.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New GC Salary Slab Item</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit GC Salary Slab Item Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="unitMin"
                                label="Unit Min"
                                onChange={formik.handleChange}
                                value={formik.values.unitMin}
                                fullWidth
                                error={formik.touched.unitMin && Boolean(formik.errors.unitMin)}
                                helperText={formik.touched.unitMin && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="unitMax"
                                label="Unit Max"
                                onChange={formik.handleChange}
                                value={formik.values.unitMax}
                                fullWidth
                                error={formik.touched.unitMax && Boolean(formik.errors.unitMax)}
                                helperText={formik.touched.unitMax && formik.errors.unitMax}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}></Grid>
                        <Grid item xs={12}>
                            Amount Per Unit
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="fieldExpenses"
                                label="Field Expenses"
                                onChange={formik.handleChange}
                                value={formik.values.fieldExpenses}
                                fullWidth
                                error={formik.touched.fieldExpenses && Boolean(formik.errors.fieldExpenses)}
                                helperText={formik.touched.fieldExpenses && formik.errors.fieldExpenses}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="pri"
                                label="PRI"
                                onChange={formik.handleChange}
                                value={formik.values.pri}
                                fullWidth
                                error={formik.touched.pri && Boolean(formik.errors.pri)}
                                helperText={formik.touched.pri && formik.errors.pri}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}></Grid>
                        <Grid item xs={12}>
                            Amount Per Day
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="da"
                                label="DA"
                                onChange={formik.handleChange}
                                value={formik.values.da}
                                fullWidth
                                error={formik.touched.da && Boolean(formik.errors.da)}
                                helperText={formik.touched.da && formik.errors.da}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="ta"
                                label="TA"
                                onChange={formik.handleChange}
                                value={formik.values.ta}
                                fullWidth
                                error={formik.touched.ta && Boolean(formik.errors.ta)}
                                helperText={formik.touched.ta && formik.errors.ta}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="incentive"
                                label="Incentive"
                                onChange={formik.handleChange}
                                value={formik.values.incentive}
                                fullWidth
                                error={formik.touched.incentive && Boolean(formik.errors.incentive)}
                                helperText={formik.touched.incentive && formik.errors.incentive}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default GCSalarySlabItems;
