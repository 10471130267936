import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useBM6FieldExpenses = (id) => {
    const getBM6FieldExpensesByBM6SalarySlabId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/BM6FieldExpences/by-bm6-salaryslab-id?bm6SalarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addBM6FieldExpenses = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/BM6FieldExpences`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateBM6FieldExpenses = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/BM6FieldExpences?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteBM6FieldExpenses = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/BM6FieldExpences/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getBM6FieldExpensesByBM6SalarySlabId, addBM6FieldExpenses, updateBM6FieldExpenses, softDeleteBM6FieldExpenses };
};

export default useBM6FieldExpenses;
