import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
//MaterialUI Imports
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import SellIcon from '@mui/icons-material/Sell';

import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import usePurchaseRecordService from 'services/usePurchaseRecordService';
import { locations } from 'store/constant';

const PurchaseRecordForm = (props) => {
    //Constants
    const navigate = useNavigate();
    const { onSave, onCancel, selectedPurchaseRecord } = props;
    const { addPurchaseRecord, updatePurchaseRecord } = usePurchaseRecordService();
    const { userId } = useSelector((state) => state.userReducer);

    //States
    const [loading, setLoading] = useState();

    const formik = useFormik({
        initialValues: {
            id: selectedPurchaseRecord.id,
            date:
                selectedPurchaseRecord.date != undefined || selectedPurchaseRecord.date != null
                    ? new Date(selectedPurchaseRecord.date.toString() + 'Z').toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0],
            recordedBy: userId,
            location: selectedPurchaseRecord.location,
            note: selectedPurchaseRecord.note
        },
        validationSchema: Yup.object({
            // recordedBy: Yup.number().required('Record is required'),
            location: Yup.number(),
            note: Yup.string().max(100, 'Note must be 100 characters or less')
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedPurchaseRecord.id != 0) {
                updatePurchaseRecord(selectedPurchaseRecord.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            //setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                addPurchaseRecord(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);

                            if (response.data.newId != undefined) {
                                navigate('/purchase-details?Id=' + response.data.newId);
                            }
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedPurchaseRecord.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <SellIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Purchase Record</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <SellIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Purchase Record Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />

            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                type="date"
                                name="date"
                                label="Purchase Date"
                                onChange={formik.handleChange}
                                value={formik.values.date}
                                fullWidth
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="location-select-label">Location</InputLabel>
                                <Select
                                    labelId="location-select-label"
                                    id="location-select"
                                    label="Location"
                                    name="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                >
                                    {locations?.map((location, index) => {
                                        return (
                                            <MenuItem key={index} value={index}>
                                                <Stack direction="column">
                                                    <Typography>{location}</Typography>
                                                </Stack>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="note"
                                label="Note"
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                fullWidth
                                multiline
                                rows={3}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <LoadingButton variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </LoadingButton>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default PurchaseRecordForm;
