import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import useAmountTransactionService from 'services/useAmountTransactionService';

const GCCard = (props) => {
    const {
        employee,
        gcSalarySlabDetails,
        gcFiledExpensesDetails,
        gcGroupIncentiveDetails,
        onRefresh,
        month,
        year,
        selectedRole,
        location
    } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(30);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(90);
    const [editMode, setEditMode] = useState(true);
    const [salesUnits, setSalesUnits] = useState(0);
    const [saveGCSalary, setSaveGCSalary] = useState();
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [gcSalaryDetails, SetGCSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        //adjustment: 0,
        petrol: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedGCSalarySlab, setAppliedGCSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0
    });

    const [gcGroupIncentive, setGCGroupIncentive] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0
    });
    const [gcGroupIncentiveSalarySlabs, setGCGroupIncentiveSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0
    });
    const [units, setUnits] = useState(0);

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    const handleCalculate = () => {
        //calculated values
        //console.log('Calculate called');
        let calcFieldExpenses = appliedGCSalarySlab.fieldExpenses * unitsSold;
        let calcPRI = appliedGCSalarySlab.pri;
        let calcDA = appliedGCSalarySlab.da;
        let calcTA = appliedGCSalarySlab.ta;
        let calcPetrol = 0;
        if (employee.haveVehicle && unitsSold > 25) {
            let liters = (unitsSold / 2) * 1.5;
            calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        } else {
            calcPetrol = 0;
        }
        //let calcAmount = 0;
        // let calcAmount = 0;
        // if (unitsSold !== 0 && salesUnits !== 0) {
        //     let units = unitsSold - salesUnits;
        let calcAmount = gcGroupIncentiveSalarySlabs.amount * units;
        let calcIncentive = appliedGCSalarySlab.incentive;
        // }
        // setUnits(calcAmount);
        // const units = unitsSold - salesUnits;
        // setUnits(units);
        // let calcAmount = gcGroupIncentiveSalarySlabs.amount * units;
        //calcAmount = units;
        let calcDeduction = pendingAmountDetails?.amount;
        let calcNetAmount =
            appliedGCSalarySlab.fixedSalary +
            calcFieldExpenses +
            calcPRI +
            calcDA +
            calcTA +
            calcPetrol +
            calcAmount +
            calcIncentive -
            calcDeduction;

        SetGCSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: appliedGCSalarySlab.fixedSalary,
            units: unitsSold,
            fieldExpenses: calcFieldExpenses,

            pri: calcPRI,
            da: calcDA,
            ta: calcTA,
            incentive: calcIncentive,
            petrol: calcPetrol,
            // adjustment: adjustment,
            deduction: calcDeduction,
            netAmount: calcNetAmount
        }));
        setGCGroupIncentive((prev) => ({
            ...prev,
            fixedSalary: gcGroupIncentiveSalarySlabs.fixedSalary,
            units: units,
            amount: calcAmount
            // netAmount: calcNetAmount
        }));
    };
    //response.data.reduce((total, item) => total + item.units, 0);

    const handleSetSalarySlabData = () => {
        console.log(gcFiledExpensesDetails);
        let selectedData = gcFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let gcGroupIncentiveData = gcGroupIncentiveDetails?.filter((item) => {
            return units >= item.unitMin && units <= item.unitMax;
        });
        //console.log(gcGroupIncentiveData);
        if (gcGroupIncentiveData.length > 0) {
            let data = {
                fixedSalary: gcSalarySlabDetails.fixedSalary,
                minUnit: gcGroupIncentiveData[0].unitMin,
                maxUnit: gcGroupIncentiveData[0].unitMax,
                amount: gcGroupIncentiveData[0].amount
            };
            setGCGroupIncentiveSalarySlabs(data);
            handleCalculate();
        }
        //console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: gcSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                pri: selectedData[0].pri,
                da: selectedData[0].da,
                ta: selectedData[0].ta,
                incentive: selectedData[0].incentive
            };
            setAppliedGCSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesMonthlyData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                console.log(response);
                setUnitsSold(response.data.unit);
            }
        });
    };
    const handelGetSalesData = () => {
        getUnitsSold(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setSalesUnits(response.data);
            }
        });
    };

    const gcSaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            unitSold: unitsSold,
            workDays: workDays,
            // adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: gcSalaryDetails.fixedSalary,
            fieldExpences: gcSalaryDetails.fieldExpenses,
            incentive: gcSalaryDetails.incentive,
            pri: gcSalaryDetails.pri,
            da: gcSalaryDetails.da,
            ta: gcSalaryDetails.ta,
            petrol: gcSalaryDetails.petrol,
            groupIncentive: gcGroupIncentive.amount,
            deduction: gcSalaryDetails.deduction,
            netSalary: gcSalaryDetails.netAmount,
            remark: 'string'
        };
        console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setSaveGCSalary(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
        //gcSaveSalary();
    }, [petrolRate, workDays, units, unitsSold, gcSalarySlabDetails, gcFiledExpensesDetails, gcGroupIncentiveDetails, employee.id]);

    useEffect(() => {
        handelGetSalesMonthlyData();
    }, [month, year, employee.id]);

    useEffect(() => {
        handelGetSalesData();
        let res = 0;
        if (unitsSold !== 0 && salesUnits !== 0) {
            res = unitsSold - salesUnits;
        }
        setUnits(res);
    }, [month, year, employee.id, unitsSold, salesUnits]);

    useEffect(() => {
        if (gcSalarySlabDetails && gcFiledExpensesDetails && gcGroupIncentiveDetails) {
            handleSetSalarySlabData();
        }
    }, [gcSalarySlabDetails, gcFiledExpensesDetails, gcGroupIncentiveDetails, unitsSold, units]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                {editMode && (
                    <Button
                        size="small"
                        onClick={() => {
                            handelGetSalesData();
                            handelGetSalesMonthlyData();
                            handleCalculate();
                        }}
                    >
                        <RefreshIcon fontSize="small" />
                    </Button>
                )}
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />
                    {/* <TextField
                        label="Adjustments"
                        size="small"
                        fullWidth
                        value={adjustment}
                        onChange={(e) => {
                            SetGCSalaryDetails((prev) => ({
                                ...prev,
                                adjustment: e.target.value
                            }));
                        }}
                    /> */}
                    <TextField
                        label="Petrol Rate"
                        size="small"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" spacing={3}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Units Sold
                        </Typography>
                        <Typography>{unitsSold}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Work Days
                        </Typography>
                        <Typography>{workDays}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Petrol Rate
                        </Typography>
                        <Typography>₹ {petrolRate}</Typography>
                    </Stack>
                    {/* <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Adjustments
                        </Typography>
                        <Typography>₹ {gcSalaryDetails.adjustment}</Typography>
                    </Stack> */}
                </Stack>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${gcSalaryDetails.fixedSalary} (From Table)`}
                    value={gcSalaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedGCSalarySlab.fieldExpenses} (From Table)`}
                    value={gcSalaryDetails.fieldExpenses}
                />
                <SalaryDetailComponent
                    title="Incentive"
                    description={` ₹ ${appliedGCSalarySlab.incentive} (From Table)`}
                    value={gcSalaryDetails.incentive}
                />
                <SalaryDetailComponent title="PRI" description={`₹ ${gcSalaryDetails.pri} (From Table)`} value={gcSalaryDetails.pri} />
                <SalaryDetailComponent title="D.A." description={` ₹ ${appliedGCSalarySlab.da} (From Table)`} value={gcSalaryDetails.da} />
                <SalaryDetailComponent title="T.A." description={` ₹ ${appliedGCSalarySlab.ta} (From Table)`} value={gcSalaryDetails.ta} />
                <SalaryDetailComponent
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${unitsSold} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={gcSalaryDetails.petrol}
                />
                <SalaryDetailComponent
                    title="Group Incentive"
                    description={`${unitsSold - salesUnits} Units x ₹ ${gcGroupIncentiveSalarySlabs.amount} `}
                    value={gcGroupIncentive.amount}
                />
                <Divider />
                <SalaryDetailComponent
                    title="Deduction"
                    description={` ₹ ${gcSalaryDetails.deduction}`}
                    value={gcSalaryDetails.deduction}
                />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={gcSalaryDetails.netAmount} />
                <Divider />
            </Stack>

            {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={gcSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetGCSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{gcSalaryDetails.remarks == '' ? '---' : gcSalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? (
                <> */}
            <Button
                variant="outlined"
                onClick={() => {
                    handleCalculate();
                }}
            >
                Calculate
            </Button>
            <LoadingButton
                loading={submitting}
                variant="contained"
                //value={saveGCSalary}
                onClick={() => {
                    handleCalculate();
                    gcSaveSalary();
                    //setEditMode(false);
                }}
                sx={{ marginLeft: 2 }}
            >
                Save
            </LoadingButton>
            {/* </>
            ) : (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    Edit
                </Button>
            )} */}
        </Card>
    );
};

export default GCCard;
