import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icon imports
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const ROBM5SalarySheet = (props) => {
    const { employee, bm5SalarySlabDetails, bm5FiledExpensesDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    // const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [bm5SalaryDetails, SetBM5SalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        incentive: 0,
        //adjustment: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedBM5SalarySlab, setAppliedBM5SalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        incentive: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();

    //handlers
    //calculated values
    const handleCalculate = () => {
        let calcDeduction = pendingAmountDetails?.amount;
        // let calcAmount = appliedBM5SalarySlab.amount * unitsSold;
        // let calcNetAmount = appliedBM5SalarySlab.fixedSalary + calcAmount;
        SetBM5SalaryDetails((prev) => ({
            ...prev,
            // fixedSalary: appliedBM5SalarySlab.fixedSalary,
            // units: unitsSold,
            // amount: calcAmount,
            // adjustment: adjustment,
            // netAmount: calcNetAmount
            deduction: calcDeduction
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(bm5FiledExpensesDetails);
        let selectedData = bm5FiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: bm5SalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                amount: selectedData[0].amount,
                incentive: selectedData[0].incentive
            };
            setAppliedBM5SalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [petrolRate, workDays, unitsSold, bm5SalarySlabDetails, bm5FiledExpensesDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (bm5SalarySlabDetails && bm5FiledExpensesDetails) {
            handleSetSalarySlabData();
        }
    }, [bm5SalarySlabDetails, bm5FiledExpensesDetails, unitsSold]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
            </Stack>
            <Divider sx={{ marginY: 2 }} />

            <Stack direction="row" spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Units Sold
                    </Typography>
                    <Typography>{unitsSold}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Work Days
                    </Typography>
                    <Typography>{workDays}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Petrol Rate
                    </Typography>
                    <Typography>₹ {petrolRate}</Typography>
                </Stack>
                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {bm5SalaryDetails.adjustment}</Typography>
                </Stack> */}
            </Stack>

            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryReadOnly
                    title="Fixed Salary"
                    description={`₹ ${appliedBM5SalarySlab.fixedSalary} (From Table)`}
                    value={appliedBM5SalarySlab.fixedSalary}
                />
                <SalaryReadOnly
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedBM5SalarySlab.amount} (From Table)`}
                    value={appliedBM5SalarySlab.amount * unitsSold}
                />
                <SalaryReadOnly
                    title="Incentive"
                    description={` ₹ ${appliedBM5SalarySlab.incentive} (From Table)`}
                    value={appliedBM5SalarySlab.incentive}
                />
                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${bm5SalaryDetails.deduction}`} value={bm5SalaryDetails.deduction} />
                <Divider />
                <SalaryReadOnly
                    title="Net Salary"
                    value={
                        appliedBM5SalarySlab.fixedSalary +
                        appliedBM5SalarySlab.amount * unitsSold +
                        appliedBM5SalarySlab.incentive -
                        bm5SalaryDetails.deduction
                    }
                />
                <Divider />
            </Stack>

            {/* {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={bm5SalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetBM5SalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{bm5SalaryDetails.remarks == '' ? '---' : bm5SalaryDetails.remarks}</Typography>
                </Stack>
            )}
            <Divider sx={{ marginY: 2 }} />*/}
        </Card>
    );
};

export default ROBM5SalarySheet;
