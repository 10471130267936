import {
    Button,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

const ReportList = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleChange = (e) => {
        console.log(e.target.value);
    };
    return (
        <>
            <MainCard>
                <CardHeader
                    // avatar={<CropIcon color="primary" />}
                    title="Reports"
                    sx={{ padding: 0 }}
                    action={
                        <Grid container>
                            <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    align="right"
                                    onClick={() => {
                                        navigate('/reports');
                                    }}
                                >
                                    Report
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item>
                        <FormControl>
                            <Select sx={{ width: 200 }} onChange={handleChange}>
                                <MenuItem value="">Select an option</MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate('/report1');
                                    }}
                                    value="option1"
                                >
                                    Report 1
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate('/report2');
                                    }}
                                    value="option2"
                                >
                                    Report 2
                                </MenuItem>
                                <MenuItem value="option3">Report 3</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField></TextField>
                    </Grid>
                </Grid>

                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                    <TableContainer>
                        <Table
                            sx={{
                                border: 'solid',
                                borderWidth: 1,
                                borderColor: 'divider'
                            }}
                        >
                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                <TableCell sx={{ width: 70 }}>
                                    <Typography variant="h5">Sr.No.</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h5">Name</Typography>
                                </TableCell>
                                <TableCell sx={{ width: 100 }}>
                                    <Typography variant="h5">Rate</Typography>
                                </TableCell>
                                <TableCell sx={{ width: 120 }}>
                                    <Typography variant="h5">Reported By</Typography>
                                </TableCell>
                                <TableCell sx={{ width: 120 }}>
                                    <Typography variant="h5">Reported To</Typography>
                                </TableCell>
                                <TableCell sx={{ width: 130 }}></TableCell>
                            </TableHead>
                            <TableBody>
                                {/* {productDetails?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h5"> {row.name}</Typography>
                                        <Typography sx={{ color: theme.palette.grey[600] }}>{row.description}</Typography>
                                    </TableCell>
                                    <TableCell align="left">{row.rate}</TableCell>
                                    <TableCell align="left">{row.uom}</TableCell>
                                    <TableCell align="left">{row.currentStock}</TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Edit Product details">
                                            <IconButton
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedProduct(row);
                                                    setOpenForm(true);
                                                }}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Product">
                                            <IconButton
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedProduct(row);
                                                    setOpenDeleteDialog(true);
                                                }}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </MainCard>
        </>
    );
};

export default ReportList;
