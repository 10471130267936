import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icon imports
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const ROGLSalarySheet = (props) => {
    const { employee, glSalarySlabDetails, glFiledExpensesDetails, glGroupIncentiveDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(60);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(90);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [glSalaryDetails, SetGLSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        adjustment: 0,
        petrol: 0,
        deduction: 0,
        //netAmount: 0,
        remarks: ''
    });
    const [appliedGLSalarySlab, setAppliedGLSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0
    });

    const [glGroupIncentiveSalary, setGLGroupIncentiveSalary] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0
    });
    const [glGroupIncentiveSalarySlabs, setGLGroupIncentiveSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0
    });
    const [glSalesUnit, setGLSalesUnit] = useState(0);
    const [totalUnits, setTotalUnits] = useState(0);

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    const handleCalculate = () => {
        //calculated values
        // let calcFieldExpenses = appliedGLSalarySlab.fieldExpenses * unitsSold;
        // let calcPRI = appliedGLSalarySlab.pri;
        // let calcDA = appliedGLSalarySlab.da * workDays;
        // let calcTA = appliedGLSalarySlab.ta * workDays;
        // let calcPetrol = 0;
        // if (employee.haveVehicle && unitsSold > 25) {
        //     let liters = (unitsSold / 2) * 1.5;
        //     calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        // } else {
        //     calcPetrol = 0;
        // }
        let calcDeduction = pendingAmountDetails?.amount;
        const totalUnits = unitsSold - glSalesUnit;
        setTotalUnits(totalUnits);
        let calcAmount = glGroupIncentiveSalarySlabs.amount * totalUnits;
        // let calcNetAmount =
        //     appliedGLSalarySlab.fixedSalary + calcFieldExpenses + calcPRI + calcDA + calcTA + calcPetrol + calcAmount - calcDeduction;
        SetGLSalaryDetails((prev) => ({
            ...prev,
            // fixedSalary: appliedGLSalarySlab.fixedSalary,
            // units: unitsSold,
            // fieldExpenses: calcFieldExpenses,
            // pri: calcPRI,
            // da: calcDA,
            // ta: calcTA,
            // petrol: calcPetrol,
            deduction: calcDeduction
            //adjustment: adjustment,
            // netAmount: calcNetAmount
        }));
        setGLGroupIncentiveSalary((prev) => ({
            ...prev,
            fixedSalary: glGroupIncentiveSalarySlabs.fixedSalary,
            units: totalUnits,
            amount: calcAmount
            // netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(glFiledExpensesDetails);
        let selectedData = glFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let glGroupIncentiveData = glGroupIncentiveDetails?.filter((item) => {
            return totalUnits >= item.unitMin && totalUnits <= item.unitMax;
        });
        // //console.log(data);
        if (glGroupIncentiveData.length > 0) {
            let data = {
                fixedSalary: glSalarySlabDetails.fixedSalary,
                minUnit: glGroupIncentiveData[0].unitMin,
                maxUnit: glGroupIncentiveData[0].unitMax,
                amount: glGroupIncentiveData[0].amount
            };
            setGLGroupIncentiveSalarySlabs(data);
            handleCalculate();
        }
        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: glSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                pri: selectedData[0].pri,
                da: selectedData[0].da,
                ta: selectedData[0].ta,
                incentive: selectedData[0].incentive
            };
            setAppliedGLSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };
    const handelGetSalesMonthlyData = () => {
        getUnitsSold(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setGLSalesUnit(response.data);
            }
        });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [
        petrolRate,
        workDays,
        totalUnits,
        unitsSold,
        glSalarySlabDetails,
        glFiledExpensesDetails,
        glGroupIncentiveDetails,
        glSalesUnit,
        employee.id
    ]);

    useEffect(() => {
        //handelGetSalesData();
        handelGetSalesMonthlyData();
    }, [month, year, employee.id]);

    useEffect(() => {
        handelGetSalesData();
        //handelGetSalesMonthlyData();
    }, [month, year, employee.id]);
    useEffect(() => {
        if (glSalarySlabDetails && glFiledExpensesDetails && glGroupIncentiveDetails) {
            handleSetSalarySlabData();
        }
    }, [glSalarySlabDetails, glFiledExpensesDetails, glGroupIncentiveDetails, unitsSold, totalUnits, glSalesUnit]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
            </Stack>
            <Divider sx={{ marginY: 2 }} />

            <Stack direction="row" spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Units Sold
                    </Typography>
                    <Typography>{unitsSold}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Work Days
                    </Typography>
                    <Typography>{workDays}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Petrol Rate
                    </Typography>
                    <Typography>₹ {petrolRate}</Typography>
                </Stack>
                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {glSalaryDetails.adjustment}</Typography>
                </Stack> */}
            </Stack>

            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryReadOnly
                    title="Fixed Salary"
                    description={`₹ ${appliedGLSalarySlab.fixedSalary} (From Table)`}
                    value={appliedGLSalarySlab.fixedSalary}
                />
                <SalaryReadOnly
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedGLSalarySlab.fieldExpenses} (From Table)`}
                    value={appliedGLSalarySlab.fieldExpenses * unitsSold}
                />
                <SalaryReadOnly
                    title="Incentive"
                    description={`₹ ${appliedGLSalarySlab.incentive} (From Table)`}
                    value={appliedGLSalarySlab.incentive}
                />
                <SalaryReadOnly title="PRI" description={`₹ ${appliedGLSalarySlab.pri} (From Table)`} value={appliedGLSalarySlab.pri} />
                <SalaryReadOnly
                    title="D.A."
                    description={`${workDays} Days x ₹ ${appliedGLSalarySlab.da} (From Table)`}
                    value={appliedGLSalarySlab.da}
                />
                <SalaryReadOnly
                    title="T.A."
                    description={`${workDays} Days x ₹ ${appliedGLSalarySlab.ta} (From Table)`}
                    value={appliedGLSalarySlab.ta}
                />
                <SalaryReadOnly
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${unitsSold} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={glSalaryDetails.petrol}
                />
                <SalaryReadOnly
                    title="Group Incentive"
                    description={`${totalUnits} Units x ₹ ${glGroupIncentiveSalarySlabs.amount} (From Table) `}
                    value={glGroupIncentiveSalarySlabs.amount * totalUnits}
                />

                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${glSalaryDetails.deduction}`} value={glSalaryDetails.deduction} />
                <Divider />
                <SalaryReadOnly
                    title="Net Salary"
                    value={
                        appliedGLSalarySlab.fixedSalary +
                        appliedGLSalarySlab.fieldExpenses * unitsSold +
                        appliedGLSalarySlab.incentive +
                        appliedGLSalarySlab.pri +
                        appliedGLSalarySlab.da +
                        appliedGLSalarySlab.ta +
                        glGroupIncentiveSalarySlabs.amount * totalUnits -
                        glSalaryDetails.deduction
                    }
                />
                <Divider />
            </Stack>
            {/* 
            {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={glSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetGLSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{glSalaryDetails.remarks == '' ? '---' : glSalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
           */}
        </Card>
    );
};

export default ROGLSalarySheet;
