import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

//const GCCard = (props) => {

const ROTMSalarySheet = (props) => {
    const { employee, tmSalarySlabDetails, tmFiledExpensesDetails, tmDailyAllowanceDetails, tmPRIsDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [tmSalaryDetails, SetTMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0,
        //campToCamp: 0,
        //adjustment: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedTMSalarySlab, setAppliedTMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0
    });

    const [tmDailyAllowanceSalary, setTMDailyAllowanceSalary] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        campToCamp: 0
    });
    const [tmDAsSalarySlabs, setTMDAsSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        campToCamp: 0
    });

    const [tmPRIsSalary, setTMPRIsSalary] = useState({
        fixedSalary: 0,
        units: 0,
        pri: 0
    });
    const [tmPRIsSalarySlabs, setTMPRIsSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        pri: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    //calculated values
    const handleCalculate = () => {
        let calcAmount = appliedTMSalarySlab.fieldExpenses * unitsSold;
        let calcPetrol = appliedTMSalarySlab.petrol;
        let calcPRIAmount = tmPRIsSalarySlabs.pri;
        // let calcDA = appliedGCSalarySlab.da * workDays;
        // let calcTA = appliedGCSalarySlab.ta * workDays;
        // let calcPetrol = 0;
        // if (employee.haveVehicle && unitsSold > 25) {
        //     let liters = (unitsSold / 2) * 1.5;
        //     calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        // } else {
        //     calcPetrol = 0;
        // }

        let calcDAsAmount = tmDAsSalarySlabs.amount;
        let calcCampToCamp = tmDAsSalarySlabs.campToCamp;

        let calcNetAmount = appliedTMSalarySlab.fixedSalary + calcAmount + calcPetrol + calcDAsAmount + calcCampToCamp + calcPRIAmount;
        let calcDeduction = pendingAmountDetails?.amount;
        SetTMSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: appliedTMSalarySlab.fixedSalary,
            units: unitsSold,
            fieldExpenses: calcAmount,
            // fieldExpenses: calcFieldExpenses,
            // pri: calcPRI,
            // da: calcDA,
            // ta: calcTA,
            //campToCamp: calcCampToCamp,
            petrol: calcPetrol,
            //adjustment: adjustment,
            deduction: calcDeduction,
            netAmount: calcNetAmount
        }));
        setTMDailyAllowanceSalary((prev) => ({
            ...prev,
            fixedSalary: tmDAsSalarySlabs.fixedSalary,
            units: unitsSold,
            amount: calcDAsAmount,
            campToCamp: calcCampToCamp
            //netAmount: calcNetAmount
        }));
        setTMPRIsSalary((prev) => ({
            ...prev,
            fixedSalary: tmPRIsSalarySlabs.fixedSalary,
            units: unitsSold,
            pri: calcPRIAmount

            //netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(tmFiledExpensesDetails);
        let selectedData = tmFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedDAsData = tmDailyAllowanceDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedPRIsData = tmPRIsDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        //console.log(selectedDAsData);

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: tmSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                petrol: selectedData[0].petrol,
                incentive: selectedData[0].incentive
            };
            setAppliedTMSalarySlab(data);
            handleCalculate();
        }

        if (selectedDAsData.length > 0) {
            let data = {
                fixedSalary: tmSalarySlabDetails.fixedSalary,
                minUnit: selectedDAsData[0].unitMin,
                maxUnit: selectedDAsData[0].unitMax,
                amount: selectedDAsData[0].amount,
                campToCamp: selectedDAsData[0].campToCamp
            };
            setTMDAsSalarySlabs(data);
            handleCalculate();
        }
        if (selectedPRIsData.length > 0) {
            let data = {
                fixedSalary: tmSalarySlabDetails.fixedSalary,
                minUnit: selectedPRIsData[0].unitMin,
                maxUnit: selectedPRIsData[0].unitMax,
                pri: selectedPRIsData[0].pri
            };
            setTMPRIsSalarySlabs(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [petrolRate, workDays, unitsSold, tmSalarySlabDetails, tmFiledExpensesDetails, tmDailyAllowanceDetails, tmPRIsDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (tmSalarySlabDetails && tmFiledExpensesDetails && tmDailyAllowanceDetails && tmPRIsDetails) {
            handleSetSalarySlabData();
        }
    }, [tmSalarySlabDetails, tmFiledExpensesDetails, tmDailyAllowanceDetails, tmPRIsDetails, unitsSold]);
    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                {/* {editMode && (
                    <Button
                        size="small"
                        onClick={() => {
                            handelGetSalesData();
                            handleCalculate();
                        }}
                    >
                        <RefreshIcon fontSize="small" />
                    </Button>
                )} */}
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />
                    <TextField
                        label="Adjustments"
                        size="small"
                        fullWidth
                        value={adjustment}
                        onChange={(e) => {
                            SetTMSalaryDetails((prev) => ({
                                ...prev,
                                adjustment: e.target.value
                            }));
                        }}
                    />
                    <TextField
                        label="Petrol Rate"
                        size="small"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : ( */}
            <Stack direction="row" spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Units Sold
                    </Typography>
                    <Typography>{unitsSold}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Work Days
                    </Typography>
                    <Typography>{workDays}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Petrol Rate
                    </Typography>
                    <Typography>₹ {petrolRate}</Typography>
                </Stack>
                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {tmSalaryDetails.adjustment}</Typography>
                </Stack> */}
            </Stack>
            {/* )} */}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryReadOnly
                    title="Fixed Salary"
                    description={`₹ ${appliedTMSalarySlab.fixedSalary} (From Table)`}
                    value={appliedTMSalarySlab.fixedSalary}
                />
                <SalaryReadOnly
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedTMSalarySlab.fieldExpenses} (From Table)`}
                    value={appliedTMSalarySlab.fieldExpenses * unitsSold}
                />
                <SalaryReadOnly
                    title="Petrol"
                    description={`₹ ${appliedTMSalarySlab.petrol} (From Table)`}
                    value={appliedTMSalarySlab.petrol}
                />
                <SalaryReadOnly
                    title="Incentive"
                    description={`₹ ${appliedTMSalarySlab.incentive} (From Table)`}
                    value={appliedTMSalarySlab.incentive}
                />
                <SalaryReadOnly title="DAs" description={`₹ ${tmDAsSalarySlabs.amount} (From Table)`} value={tmDAsSalarySlabs.amount} />
                <SalaryReadOnly
                    title="Camp To Camp"
                    description={`₹ ${tmDAsSalarySlabs.campToCamp} (From Table)`}
                    value={tmDAsSalarySlabs.campToCamp}
                />
                <SalaryReadOnly title="PRI" description={` ${tmPRIsSalarySlabs.pri} (From Table)`} value={tmPRIsSalarySlabs.pri} />

                {/* <SalaryReadOnly title="PRI" description={`₹ ${gcSalaryDetails.pri} (From Table)`} value={gcSalaryDetails.pri} />
                <SalaryReadOnly
                    title="D.A."
                    description={`${workDays} Days x ₹ ${appliedGCSalarySlab.da} (From Table)`}
                    value={gcSalaryDetails.da}
                />
                <SalaryReadOnly
                    title="T.A."
                    description={`${workDays} Days x ₹ ${appliedGCSalarySlab.ta} (From Table)`}
                    value={gcSalaryDetails.ta}
                /> */}
                {/* <SalaryReadOnly
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${unitsSold} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={tmSalaryDetails.petrol}
                /> */}
                {/* <SalaryReadOnly
                    title="Group Incentive"
                    description={`${unitsSold} Units x ₹ ${salarySlabs.amount} `}
                    value={incentiveSalary.amount}
                /> */}
                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${tmSalaryDetails.deduction}`} value={tmSalaryDetails.deduction} />
                <Divider />
                <SalaryReadOnly
                    title="Net Salary"
                    value={
                        appliedTMSalarySlab.fixedSalary +
                        appliedTMSalarySlab.fieldExpenses * unitsSold +
                        appliedTMSalarySlab.petrol +
                        appliedTMSalarySlab.incentive +
                        tmDAsSalarySlabs.amount +
                        tmDAsSalarySlabs.campToCamp +
                        tmPRIsSalarySlabs.pri -
                        tmSalaryDetails.deduction
                    }
                />
                <Divider />
            </Stack>

            {/* {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={tmSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetTMSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{tmSalaryDetails.remarks == '' ? '---' : tmSalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
            {editMode ? (
                <>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            handleCalculate();
                        }}
                    >
                        Calculate
                    </Button>
                    <LoadingButton
                        loading={submitting}
                        variant="contained"
                        onClick={() => {
                            //handleCalculate();
                            tmSaveSalary();
                            setEditMode(false);
                        }}
                        sx={{ marginLeft: 2 }}
                    >
                        Save
                    </LoadingButton>
                </>
            ) : (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    Edit
                </Button>
            )} */}
        </Card>
    );
};

export default ROTMSalarySheet;
