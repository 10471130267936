import React, { useState, useEffect } from 'react';

//MaterialUI Imports
import { Divider, Stack, Typography, Card, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icon imports
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const ROSESalarySheet = (props) => {
    const { employee, salarySlabDetails, filedExpensesDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);

    const [pendingAmountDetails, setPendingAmountDetails] = useState();
    const [salaryDetails, SetSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        //adjustment: 0,
        petrol: 0,
        incentive: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });

    const [appliedSalarySlab, setAppliedSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        deduction: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    const { getUnitsSold } = useSalesRecordService();
    const { addSalaries } = useSalariesService();
    //handlers
    const handleCalculate = () => {
        //calculated values
        let calcFieldExpenses = appliedSalarySlab.fieldExpenses * unitsSold;
        let calcPRI = appliedSalarySlab.pri;
        let calcDA = appliedSalarySlab.da * workDays;
        let calcTA = appliedSalarySlab.ta * workDays;
        let calcPetrol = 0;
        if (employee.haveVehicle && unitsSold > 25) {
            let liters = (unitsSold / 2) * 1.5;
            calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        } else {
            calcPetrol = 0;
        }
        let calcIncentive = appliedSalarySlab.incentive;
        let calcDeduction = pendingAmountDetails?.amount;

        let calcNetAmount =
            appliedSalarySlab.fixedSalary + calcFieldExpenses + calcPRI + calcDA + calcTA + calcPetrol + calcIncentive - calcDeduction;

        SetSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: appliedSalarySlab.fixedSalary,
            units: unitsSold,
            fieldExpenses: calcFieldExpenses,
            pri: calcPRI,
            da: calcDA,
            ta: calcTA,
            petrol: calcPetrol,
            deduction: calcDeduction,
            incentive: calcIncentive,
            // adjustment: adjustment,
            netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(filedExpensesDetails);
        let selectedData = filedExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: salarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                pri: selectedData[0].pri,
                da: selectedData[0].da,
                ta: selectedData[0].ta,
                incentive: selectedData[0].incentive
            };
            setAppliedSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getUnitsSold(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data);
            }
        });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [petrolRate, workDays, unitsSold, salarySlabDetails, filedExpensesDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (salarySlabDetails && filedExpensesDetails) {
            handleSetSalarySlabData();
        }
    }, [salarySlabDetails, filedExpensesDetails, unitsSold]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />
                   <TextField
                        label="Adjustments"
                        size="small"
                        fullWidth
                        value={adjustment}
                        onChange={(e) => {
                            SetGCSalaryDetails((prev) => ({
                                ...prev,
                                adjustment: e.target.value
                            }));
                        }}
                    /> 
                    <TextField
                        label="Petrol Rate"
                        size="small"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : ( */}
            <Stack direction="row" spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Units Sold
                    </Typography>
                    <Typography>{unitsSold}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Work Days
                    </Typography>
                    <Typography>{workDays}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Petrol Rate
                    </Typography>
                    <Typography>₹ {petrolRate}</Typography>
                </Stack>
                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {salaryDetails.adjustment}</Typography>
                </Stack> */}
            </Stack>
            {/* // )} */}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryReadOnly
                    title="Fixed Salary"
                    description={`₹ ${appliedSalarySlab.fixedSalary} (From Table)`}
                    value={appliedSalarySlab.fixedSalary}
                />
                <SalaryReadOnly
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedSalarySlab.fieldExpenses} (From Table)`}
                    value={appliedSalarySlab.fieldExpenses * unitsSold}
                />
                <SalaryReadOnly title="PRI" description={`₹ ${appliedSalarySlab.pri} (From Table)`} value={appliedSalarySlab.pri} />
                <SalaryReadOnly
                    title="D.A."
                    description={`${workDays} Days x ₹ ${appliedSalarySlab.da} (From Table)`}
                    value={appliedSalarySlab.da * workDays}
                />
                <SalaryReadOnly
                    title="T.A."
                    description={`${workDays} Days x ₹ ${appliedSalarySlab.ta} (From Table)`}
                    value={appliedSalarySlab.ta * workDays}
                />
                <SalaryReadOnly
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${unitsSold} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={salaryDetails.petrol}
                />
                <SalaryReadOnly
                    title="Incentive."
                    description={` ₹ ${appliedSalarySlab.incentive} (From Table)`}
                    value={appliedSalarySlab.incentive}
                />
                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${salaryDetails.deduction}`} value={salaryDetails.deduction} />
                <Divider />
                <SalaryReadOnly
                    title="Net Salary"
                    value={
                        appliedSalarySlab.fixedSalary +
                        appliedSalarySlab.fieldExpenses * unitsSold +
                        appliedSalarySlab.pri +
                        appliedSalarySlab.da * workDays +
                        appliedSalarySlab.ta * workDays +
                        appliedSalarySlab.incentive +
                        salaryDetails.petrol -
                        salaryDetails.deduction
                    }
                />
                <Divider />
            </Stack>

            {/* {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={salaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{salaryDetails.remarks == '' ? '---' : salaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
          */}
        </Card>
    );
};

export default ROSESalarySheet;
