import React, { useEffect, useState } from 'react';
//import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Stack,
    Alert,
    Snackbar,
    Grid,
    Card,
    Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import BackpackIcon from '@mui/icons-material/Backpack';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import usePackService from 'services/usePackService';
import PackForm from './PackForm';
import NoResults from 'assets/images/noresults.png';

const Packs = () => {
    //Constants
    const theme = useTheme();
    const { getPacks, softDeletePacks } = usePackService();
    const navigate = useNavigate();

    //States
    const [packs, setPacks] = useState([]);
    const [selectedPack, setSelectedPack] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [isError, setIsError] = useState();

    //Methods
    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getPackList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getPackList = () => {
        setPacks(undefined);
        getPacks()
            .then((response) => {
                console.log(response);
                setPacks(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    useEffect(() => {
        getPackList();
    }, []);

    const handleDelete = () => {
        softDeletePacks(selectedPack.id)
            .then((response) => {
                getPackList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };
    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<BackpackIcon color="primary" />}
                    title="Pack List"
                    sx={{ padding: 0 }}
                    action={
                        <Grid container>
                            <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    align="right"
                                    onClick={() => {
                                        setSelectedPack({
                                            id: 0,
                                            name: '',
                                            description: '',
                                            unit: 0
                                        });
                                        setOpenForm(true);
                                    }}
                                >
                                    Add New Packs
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />

                <Divider sx={{ marginY: 3 }} />
                <Grid item align="right" sx={{ mb: 2, display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        align="right"
                        onClick={() => {
                            setSelectedPack({
                                id: 0,
                                name: '',
                                description: '',
                                unit: 0
                            });
                            setOpenForm(true);
                        }}
                    >
                        Add New Packs
                    </Button>
                </Grid>

                {packs != undefined || packs != null ? (
                    <>
                        {packs?.length === 0 ? (
                            <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                <img src={NoResults} alt="No Result" width="150px" />
                                <Typography variant="h4" fontWeight={100}>
                                    There are no pack details found
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableCell sx={{ width: 70 }}>
                                                    <Typography variant="h5">Sr.No.</Typography>{' '}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h5">Name</Typography>{' '}
                                                </TableCell>
                                                <TableCell sx={{ width: 170 }}>
                                                    <Typography variant="h5">Description</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 100 }}>
                                                    <Typography variant="h5">Unit</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 100 }}>
                                                    <Typography variant="h5">Price</Typography>
                                                </TableCell>

                                                <TableCell sx={{ width: 180 }}></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {packs?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Link href={'/pack-details?Id=' + row.id}>{row.name}</Link>
                                                        </TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">{row.unit}</TableCell>
                                                        <TableCell align="left">{row.price}</TableCell>

                                                        <TableCell align="right">
                                                            <Tooltip title="View Pack details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        navigate('/pack-details?Id=' + row.id);
                                                                    }}
                                                                >
                                                                    <ArticleIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Packs details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedPack(row);
                                                                        setOpenForm(true);
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip title="Delete Packs">
                                                                <IconButton
                                                                    color="error"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedPack(row);
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {packs?.map((row, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sx={{ mb: 0.5, display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                    >
                                        <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="h5">Name:</Typography>
                                                <Typography>{row.name}</Typography>
                                            </Stack>

                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Description:</Typography>
                                                <Typography>{row.description}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Unit:</Typography>
                                                <Typography>{row.unit}</Typography>
                                            </Stack>

                                            <Divider sx={{ mt: 1 }} />
                                            <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                <Tooltip title="View Pack details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            navigate('/pack-details?Id=' + row.id);
                                                        }}
                                                    >
                                                        <ArticleIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Packs details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedPack(row);
                                                            setOpenForm(true);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Delete Packs">
                                                    <IconButton
                                                        color="error"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedPack(row);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            Getting Pack List...
                        </Typography>
                        <CircularProgress />
                    </Stack>
                )}

                {/* </Grid> */}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <PackForm onSave={handleSave} onCancel={handleCancel} selectedPack={selectedPack} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Pack Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this pack details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%', backgroundColor: theme.palette.success.main }}>
                    <Typography sx={{ color: theme.palette.grey[50] }}> Pack deleted succesfully!!</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default Packs;
