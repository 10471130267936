import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const UseABMPRisService = () => {
    const getABMPRIs = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/ABMPRIs/by-abm-salaryslab-id?abmsalarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addABMPRIs = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/ABMPRIs`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateABMPRIs = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/ABMPRIs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteABMPRIs = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/ABMPRIs/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getABMPRIs, addABMPRIs, updateABMPRIs, softDeleteABMPRIs };
};

export default UseABMPRisService;
