import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useSESalarySlabsService = () => {
    const getSESalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/SESalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSESalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/SESalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateSESalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SESalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { getSESalarySlab, getSESalarySlabById, updateSESalarySlab };
};

export default useSESalarySlabsService;
