import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import useProductService from 'services/useProductService';
import usePackService from 'services/usePackService';
import useProductPackMappingService from 'services/useProductPackMappingService';
import PackForm from './PackForm';

const PackDetails = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { searchProduct } = useProductService();
    const { getPackById } = usePackService();
    const { addProductPackMapping, deleteProductPackMapping, getAllListProductPackMappingByPackId } = useProductPackMappingService();

    //States
    const [pack, setPack] = useState();
    const [productList, setProductList] = useState();

    const [productMappingList, setProductMappingList] = useState([]);
    const [openForm, setOpenForm] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState();
    const [openToast, setOpenToast] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [quantityValue, setQuantityValue] = useState(0);

    const [disable, setDisable] = useState(false);

    //Methods

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    // product list
    const getProductList = () => {
        searchProduct(0, 10000000, '', 0, 0, -1)
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    setProductList(response.data.list);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // display product list in table
    const getProductMappingList = () => {
        if (searchParams.get('Id') != null) {
            getAllListProductPackMappingByPackId(searchParams.get('Id'))
                .then((response) => {
                    setProductMappingList(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    // Add product using product pack mapping
    const addProduct = () => {
        let data = {
            productId: selectedProduct.id,
            packId: searchParams.get('Id'),
            quntity: quantityValue
        };
        setDisable(true);
        addProductPackMapping(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setQuantityValue(0);
                    setOpenToast(true);
                    setDisable(false);
                    getProductMappingList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // get pack data
    const getPackData = () => {
        if (searchParams.get('Id') != null) {
            getPackById(searchParams.get('Id'))
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        setPack(response.data);
                    } else {
                        console.log('Error');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    // delete product pack mapping
    const handleDelete = () => {
        deleteProductPackMapping(selectedProduct.id)
            .then((response) => {
                getProductMappingList();
                setOpenDeleteDialog(false);
            })

            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };
    //useEffects
    useEffect(() => {
        getProductList();
        getPackData();
        getProductMappingList();
    }, []);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<InventoryIcon color="primary" />}
                    title="Pack Details"
                    sx={{ padding: 0 }}
                    action={
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate('/packs');
                            }}
                            sx={{ marginRight: 1 }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    }
                />
                <Divider sx={{ marginY: 3 }} />
                {pack != undefined ? (
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingX: 1 }} spacing={2}>
                        <Grid item xs={10}>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h5" sx={{ mb: 1, color: theme.palette.grey[700] }}>
                                    Name:
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {pack.name}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h5" sx={{ mb: 1, color: theme.palette.grey[700] }}>
                                    Description:
                                </Typography>
                                <Typography variant="h5">{pack.description}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h5" sx={{ mb: 1, color: theme.palette.grey[700] }}>
                                    Unit:
                                </Typography>
                                <Typography variant="h5">{pack.unit}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                    Price:
                                </Typography>
                                <Typography variant="h5">{pack.price}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    setOpenForm(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                ) : null}
                <Divider sx={{ marginY: 2 }} />

                <Typography variant="h4" sx={{ marginY: 2, fontWeight: 'bold', color: theme.palette.secondary.dark }}>
                    Products in this pack ({productMappingList?.productPackMappings?.length})
                </Typography>
                <Grid container sx={{ display: 'flex', alignItems: 'center', mb: 2 }} direction="row" spacing={4}>
                    <Grid item sm={8} xs={12}>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="product-autocomplete"
                            getOptionLabel={(productList) => `${productList.name} `}
                            options={productList}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            noOptionsText={'No Item Found'}
                            onChange={(event, value) => {
                                setSelectedProduct(value);
                            }}
                            renderOption={(props, productList) => (
                                <Box component="li" {...props} key={productList.id}>
                                    {productList.name}
                                </Box>
                            )}
                            renderInput={(params) => <TextField label="Search / Select Product" size="small" fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            name="quantityValue"
                            label="Quantity"
                            type="number"
                            value={quantityValue}
                            onChange={(event) => {
                                setQuantityValue(event.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                        ></TextField>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Button
                            variant="contained"
                            direction="row"
                            disabled={disable}
                            onClick={() => {
                                //  setDisable(true);
                                addProduct();
                            }}
                            type="submit"
                        >
                            Add Product
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {productMappingList != undefined ? (
                            <Grid container spacing={2}>
                                {productMappingList.productPackMappings?.map((data, index) => (
                                    <Grid item key={index} sm={6}>
                                        <Card
                                            variant="outlined"
                                            sx={{ paddingX: 2, paddingBottom: 1.3, backgroundColor: theme.palette.grey[50] }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} xs={12} align="left" sx={{ mt: 2 }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                                            Product Name:
                                                        </Typography>
                                                        <Typography variant="h5">{data.product.name}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item sm={3} xs={12} align="center" sx={{ mt: 2 }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                                            Quantity:
                                                        </Typography>
                                                        <Typography variant="h5">{data.quntity}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item sm={3} xs={12} align="right" sx={{ mt: 1 }}>
                                                    <Tooltip title="Delete Product ">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                setSelectedProduct(data);
                                                                setOpenDeleteDialog(true);
                                                            }}
                                                        >
                                                            <DeleteIcon color="error" fontSize="12" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </MainCard>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Product
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this product ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <PackForm
                        onSave={() => {
                            setOpenForm(false);
                            getPackData();
                        }}
                        onCancel={() => {
                            setOpenForm(false);
                        }}
                        selectedPack={pack}
                    />
                </DialogContent>
            </Dialog>

            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%', backgroundColor: theme.palette.success.main }}>
                    <Typography sx={{ color: theme.palette.grey[50] }}> Product added succesfully!!</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default PackDetails;
