import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//MaterialUI Imports
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import CategoryIcon from '@mui/icons-material/Category';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import { LoadingButton } from '@mui/lab';
import useAmountTransactionService from 'services/useAmountTransactionService';

const AmountTransactionForm = (props) => {
    //Constants
    const { addAmountTransaction, updateAmountTransaction } = useAmountTransactionService();
    const { onSave, onCancel, selectedAmountTransaction, selectedReceiver } = props;
    const { userId } = useSelector((state) => state.userReducer);
    //States
    const [loading, setLoading] = useState(false);

    //Methods
    const formik = useFormik({
        initialValues: {
            id: selectedAmountTransaction.id,
            senderId: userId,
            receiverId: selectedReceiver.id,
            amount: selectedAmountTransaction.amount,
            date:
                selectedAmountTransaction.date != undefined || selectedAmountTransaction.date != null
                    ? new Date(selectedAmountTransaction.date.toString() + 'Z').toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0]
        },
        validationSchema: Yup.object({
            amount: Yup.number()
        }),
        onSubmit: (values) => {
            console.log('values', values);
            setLoading(true);
            if (selectedAmountTransaction.id != 0) {
                updateAmountTransaction(selectedAmountTransaction.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                addAmountTransaction(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    // useEffect(() => {
    //     console.log(employees);

    // }, []);

    console.log(selectedReceiver);
    return (
        <>
            <Grid align="center">
                {selectedAmountTransaction.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <CategoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Amount Transaction</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <CategoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Amount Transaction</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="receiver-select-label">Receiver Name</InputLabel>
                                <Select
                                    labelId="receiver-select-label"
                                    id="receiver-select"
                                    label="Receiver Name"
                                    name="receiverId"
                                    value={selectedReceiver.id}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={selectedReceiver.id}>
                                        <Stack direction="column">
                                            <Typography>{selectedReceiver.firstName}</Typography>
                                        </Stack>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                type="date"
                                name="date"
                                label="Date"
                                onChange={formik.handleChange}
                                value={formik.values.date}
                                fullWidth
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="amount"
                                label="Amount"
                                onChange={formik.handleChange}
                                value={formik.values.amount}
                                fullWidth
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default AmountTransactionForm;
