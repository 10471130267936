import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useProductPackMappingService = () => {
    const getAllListProductPackMappingByPackId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/ProductPackMappings/GetAllListByPackId?PackId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getAllProductPackMappingByProductId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/ProductPackMappings/GetAllByProductId?ProductId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addProductPackMapping = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/ProductPackMappings`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateProductPackMapping = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/ProductPackMappings?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    // const softDeleteProductPackMapping = async (id) => {
    //     try {
    //         const response = await axiosAuth.delete(`/api/ProductPackMappings/soft-delete?Id=${id}`);
    //         return handleResponse(response);
    //     } catch (error) {
    //         return handleError(error);
    //     }
    // };

    const deleteProductPackMapping = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/ProductPackMappings?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getAllListProductPackMappingByPackId,
        getAllProductPackMappingByProductId,
        addProductPackMapping,
        updateProductPackMapping,
        deleteProductPackMapping
        //softDeleteProductPackMapping
    };
};

export default useProductPackMappingService;
