// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconCash
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconCash
};

const adminMenuItems = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     type: 'item',
            //     icon: icons.IconDashboard,
            //     url: '/',
            //     breadcrumbs: false
            // },
            {
                id: 'employees',
                title: 'Employees',
                type: 'item',
                icon: icons.IconUsers,
                url: '/employees',
                breadcrumbs: false
            },
            {
                id: 'master',
                title: 'Products Master',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'categories',
                        title: 'Categories',
                        type: 'item',
                        url: '/categories',
                        breadcrumbs: false
                    },
                    {
                        id: 'products',
                        title: 'Products',
                        type: 'item',
                        url: '/products',
                        breadcrumbs: false
                    },
                    {
                        id: 'packs',
                        title: 'Packs',
                        type: 'item',
                        url: '/packs',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'sales',
                title: 'Sales',
                type: 'item',
                icon: icons.IconFileInvoice,
                url: '/sales',
                breadcrumbs: false
            },
            {
                id: 'amount-transaction',
                title: 'Amount Transaction',
                type: 'item',
                icon: icons.IconCash,
                url: '/amount-transaction',
                breadcrumbs: false
            },
            {
                id: 'purchase',
                title: 'Purchase',
                type: 'item',
                icon: icons.IconUsers,
                url: '/purchase',
                breadcrumbs: false
            },

            {
                id: 'salry-slabs',
                title: 'Salary Slabs',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'abm',
                        title: 'ABM',
                        type: 'item',
                        url: '/abm',
                        breadcrumbs: false
                    },
                    {
                        id: 'dbm',
                        title: 'DBM',
                        type: 'item',
                        url: '/dbm',
                        breadcrumbs: false
                    },
                    {
                        id: 'gc',
                        title: 'GC',
                        type: 'item',
                        url: '/gc',
                        breadcrumbs: false
                    },
                    {
                        id: 'gl',
                        title: 'GL',
                        type: 'item',
                        url: '/gl',
                        breadcrumbs: false
                    },
                    {
                        id: 'se',
                        title: 'SE',
                        type: 'item',
                        url: '/se',
                        breadcrumbs: false
                    },
                    {
                        id: 'tm',
                        title: 'TM',
                        type: 'item',
                        url: '/tm',
                        breadcrumbs: false
                    },
                    {
                        id: 'bm-5',
                        title: 'BM-5',
                        type: 'item',
                        url: '/bm-5',
                        breadcrumbs: false
                    },
                    {
                        id: 'bm-6',
                        title: 'BM-6',
                        type: 'item',
                        url: '/bm-6',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'categories',
                    //     title: 'Categories',
                    //     type: 'item',
                    //     url: '/categories',
                    //     breadcrumbs: false
                    // }
                ]
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'collapse',
                icon: icons.IconReportAnalytics,
                breadcrumbs: false,
                children: [
                    {
                        id: 'revenue-reports',
                        title: 'Revenue Reports',
                        type: 'item',
                        url: '/revenue-reports',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    },

    {
        id: 'salaries',
        type: 'group',
        children: [
            {
                id: 'salaries-pages',
                title: 'Salaries',
                type: 'item',
                icon: icons.IconCash,
                breadcrumbs: false,
                url: '/salaries'
            }
        ]
    }

    // {
    //     id: 'settings',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'settings-pages',
    //             title: 'Settings',
    //             type: 'item',
    //             icon: icons.IconSettings,
    //             breadcrumbs: false,
    //             url: '/settings'
    //         }
    //     ]
    // }
];

export default adminMenuItems;
