import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const ROABMSalarySheet = (props) => {
    const { employee, abmSalarySlabDetails, abmFiledExpensesDetails, abmDailyAllowanceDetails, abmPRIsDetails, onRefresh, month, year } =
        props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    // const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [abmSalaryDetails, SetABMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0,
        //campToCamp: 0,
        //adjustment: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedABMSalarySlab, setAppliedABMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0
    });

    const [abmDailyAllowance, setABMDailyAllowance] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        campToCamp: 0
    });
    const [appliedABMDAsSalarySlab, setAppliedABMDAsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        campToCamp: 0
    });

    const [abmPRIsSalaryDetails, setABMPRIsSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        pri: 0
    });
    const [appliedABMPRIsSalarySlab, setAppliedABMPRIsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        pri: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    //calculated values
    const handleCalculate = () => {
        // let calcAmount = appliedABMSalarySlab.fieldExpenses * unitsSold;
        // let calcPetrol = appliedABMSalarySlab.petrol;
        // let calcPRIAmount = appliedABMPRIsSalarySlab.pri;
        // let calcDA = appliedGCSalarySlab.da * workDays;
        // let calcTA = appliedGCSalarySlab.ta * workDays;
        // let calcPetrol = 0;
        // if (employee.haveVehicle && unitsSold > 25) {
        //     let liters = (unitsSold / 2) * 1.5;
        //     calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        // } else {
        //     calcPetrol = 0;
        // }
        // let calcDAsAmount = appliedABMDAsSalarySlab.amount;
        // let calcCampToCamp = appliedABMDAsSalarySlab.campToCamp;
        // let calcNetAmount = appliedABMSalarySlab.fixedSalary + calcAmount + calcPetrol + calcDAsAmount + calcCampToCamp + calcPRIAmount;

        let calcDeduction = pendingAmountDetails?.amount;
        SetABMSalaryDetails((prev) => ({
            ...prev,
            // fixedSalary: appliedABMSalarySlab.fixedSalary,
            // units: unitsSold,
            // fieldExpenses: 0,
            // fieldExpenses: calcFieldExpenses,
            // pri: calcPRI,
            // da: calcDA,
            // ta: calcTA,
            // campToCamp: calcCampToCamp,
            // petrol: 0,
            // adjustment: adjustment,
            // netAmount: 0
            deduction: calcDeduction
        }));
        //     setABMDailyAllowance((prev) => ({
        //         ...prev,
        //         fixedSalary: appliedABMDAsSalarySlab.fixedSalary,
        //         units: unitsSold,
        //         amount: 0,
        //         campToCamp: 0
        //         //netAmount: calcNetAmount
        //     }));
        //     setABMPRIsSalaryDetails((prev) => ({
        //         ...prev,
        //         fixedSalary: appliedABMPRIsSalarySlab.fixedSalary,
        //         units: unitsSold,
        //         pri: 0
        //         //netAmount: calcNetAmount
        //     }));
    };

    const handleSetSalarySlabData = () => {
        console.log(abmFiledExpensesDetails);
        let selectedData = abmFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedDAsData = abmDailyAllowanceDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedPRIsData = abmPRIsDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        //console.log(selectedDAsData);

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                petrol: selectedData[0].petrol,
                incentive: selectedData[0].incentive
            };
            setAppliedABMSalarySlab(data);
            handleCalculate();
        }

        if (selectedDAsData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedDAsData[0].unitMin,
                maxUnit: selectedDAsData[0].unitMax,
                amount: selectedDAsData[0].amount,
                campToCamp: selectedDAsData[0].campToCamp
            };
            setAppliedABMDAsSalarySlab(data);
            handleCalculate();
        }
        if (selectedPRIsData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedPRIsData[0].unitMin,
                maxUnit: selectedPRIsData[0].unitMax,
                pri: selectedPRIsData[0].pri
            };
            setAppliedABMPRIsSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [
        petrolRate,
        workDays,
        unitsSold,
        abmSalarySlabDetails,
        abmFiledExpensesDetails,
        abmDailyAllowanceDetails,
        abmPRIsDetails,
        employee.id
    ]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (abmSalarySlabDetails && abmFiledExpensesDetails && abmDailyAllowanceDetails && abmPRIsDetails) {
            handleSetSalarySlabData();
        }
    }, [abmSalarySlabDetails, abmFiledExpensesDetails, abmDailyAllowanceDetails, abmPRIsDetails, unitsSold]);
    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
            </Stack>
            <Divider sx={{ marginY: 2 }} />

            <Stack direction="row" spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Units Sold
                    </Typography>
                    <Typography>{unitsSold}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Work Days
                    </Typography>
                    <Typography>{workDays}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Petrol Rate
                    </Typography>
                    <Typography>₹ {petrolRate}</Typography>
                </Stack>
                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {abmSalaryDetails.adjustment}</Typography>
                </Stack> */}
            </Stack>

            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryReadOnly
                    title="Fixed Salary"
                    description={`₹ ${appliedABMSalarySlab.fixedSalary} (From Table)`}
                    value={appliedABMSalarySlab.fixedSalary}
                />
                <SalaryReadOnly
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedABMSalarySlab.fieldExpenses} (From Table)`}
                    value={appliedABMSalarySlab.fieldExpenses * unitsSold}
                />
                <SalaryReadOnly
                    title="Petrol"
                    description={`₹ ${appliedABMSalarySlab.petrol} (From Table)`}
                    value={appliedABMSalarySlab.petrol}
                />
                <SalaryReadOnly
                    title="Incentive"
                    description={`₹ ${appliedABMSalarySlab.incentive} (From Table)`}
                    value={appliedABMSalarySlab.incentive}
                />
                <SalaryReadOnly
                    title="DAs"
                    description={`₹ ${appliedABMDAsSalarySlab.amount} (From Table)`}
                    value={appliedABMDAsSalarySlab.amount}
                />
                <SalaryReadOnly
                    title="Camp To Camp"
                    description={`₹ ${appliedABMDAsSalarySlab.campToCamp} (From Table)`}
                    value={appliedABMDAsSalarySlab.campToCamp}
                />
                <SalaryReadOnly
                    title="PRI"
                    description={`₹ ${appliedABMPRIsSalarySlab.pri} (From Table)`}
                    value={appliedABMPRIsSalarySlab.pri}
                />
                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${abmSalaryDetails.deduction}`} value={abmSalaryDetails.deduction} />
                <Divider />
                <SalaryReadOnly title="Deduction" description={` ₹ ${abmSalaryDetails.deduction}`} value={abmSalaryDetails.deduction} />

                <Divider />
                <SalaryReadOnly
                    title="Net Salary"
                    value={
                        appliedABMSalarySlab.fixedSalary +
                        appliedABMSalarySlab.fieldExpenses * unitsSold +
                        appliedABMSalarySlab.petrol +
                        appliedABMSalarySlab.incentive +
                        appliedABMDAsSalarySlab.amount +
                        appliedABMDAsSalarySlab.campToCamp +
                        appliedABMPRIsSalarySlab.pri -
                        abmSalaryDetails.deduction
                    }
                />
                <Divider />
            </Stack>

            {/* {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={abmSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetABMSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{abmSalaryDetails.remarks == '' ? '---' : abmSalaryDetails.remarks}</Typography>
                </Stack>
            )} 

            <Divider sx={{ marginY: 2 }} />*/}
        </Card>
    );
};

export default ROABMSalarySheet;
