import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionsIcon from '@mui/icons-material/Functions';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import useSalariesService from 'services/useSalariesService';
import useAmountTransactionService from 'services/useAmountTransactionService';

const SECard = (props) => {
    const { employee, salarySlabDetails, filedExpensesDetails, onRefresh, month, year, location } = props;

    const theme = useTheme();
    const { userId } = useSelector((state) => state.userReducer);
    const cellWidth = 70;
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(true);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [salaryDetails, SetSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        //adjustment: 0,
        petrol: 0,
        deduction: 0,
        //pendingAmountDetails: 0,
        netAmount: 0,

        remarks: ''
    });

    const [appliedSalarySlab, setAppliedSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    const { getUnitsSold } = useSalesRecordService();
    const { addSalaries } = useSalariesService();
    //handlers

    const handleCalculate = () => {
        //calculated values

        let calcFieldExpenses = appliedSalarySlab.fieldExpenses * unitsSold;
        let calcPRI = appliedSalarySlab.pri;
        let calcDA = appliedSalarySlab.da * workDays;
        let calcTA = appliedSalarySlab.ta * workDays;
        let calcPetrol = 0;
        if (employee.haveVehicle && unitsSold > 25) {
            let liters = (unitsSold / 2) * 1.5;
            calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        } else {
            calcPetrol = 0;
        }
        let calcIncentive = appliedSalarySlab.incentive;
        let calcDeduction = pendingAmountDetails?.amount;
        let calcNetAmount =
            appliedSalarySlab.fixedSalary + calcFieldExpenses + calcPRI + calcDA + calcTA + calcPetrol + calcIncentive - calcDeduction;
        //calcNetAmount = calcNetAmount - calcDeduction;
        SetSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: appliedSalarySlab.fixedSalary,
            units: unitsSold,
            fieldExpenses: calcFieldExpenses,
            pri: calcPRI,
            da: calcDA,
            ta: calcTA,
            petrol: calcPetrol,
            incentive: calcIncentive,
            //adjustment: adjustment,
            deduction: calcDeduction,
            //pendingAmountDetails: calcDeduction,
            netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(filedExpensesDetails);
        let selectedData = filedExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: salarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                pri: selectedData[0].pri,
                da: selectedData[0].da,
                ta: selectedData[0].ta,
                incentive: selectedData[0].incentive
            };
            setAppliedSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getUnitsSold(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data);
            }
        });
    };

    const seSaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            location: location,
            unitSold: unitsSold,
            workDays: workDays,
            //adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: salaryDetails.fixedSalary,
            fieldExpences: salaryDetails.fieldExpenses,
            pri: salaryDetails.pri,
            da: salaryDetails.da,
            ta: salaryDetails.ta,
            petrol: salaryDetails.petrol,
            incentive: salaryDetails.incentive,
            //groupIncentive: gcGroupIncentive.amount,
            deduction: salaryDetails.deduction,
            netSalary: salaryDetails.netAmount,
            remark: 'string'
        };
        console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setSaveGCSalary(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [petrolRate, workDays, unitsSold, salarySlabDetails, filedExpensesDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [location, month, year, employee.id]);

    useEffect(() => {
        if (salarySlabDetails && filedExpensesDetails) {
            handleSetSalarySlabData();
        }
    }, [salarySlabDetails, filedExpensesDetails, unitsSold]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                <Button
                    size="small"
                    onClick={() => {
                        handelGetSalesData();
                        handleCalculate();
                    }}
                >
                    <RefreshIcon fontSize="small" />
                </Button>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            <Stack direction="row" spacing={1}>
                <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                <TextField
                    label="Work Days"
                    fullWidth
                    size="small"
                    value={workDays}
                    onChange={(e) => {
                        setWorkDays(e.target.value);
                    }}
                />
                {/* <TextField
                    label="Adjustments"
                    size="small"
                    fullWidth
                    value={adjustment}
                    onChange={(e) => {
                        SetSalaryDetails((prev) => ({
                            ...prev,
                            adjustment: e.target.value
                        }));
                    }}
                /> */}
                <TextField
                    label="Petrol Rate"
                    size="small"
                    fullWidth
                    value={petrolRate}
                    onChange={(e) => {
                        setPetrolRate(e.target.value);
                    }}
                />
            </Stack>

            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${salaryDetails.fixedSalary} (From Table)`}
                    value={salaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedSalarySlab.fieldExpenses} (From Table)`}
                    value={salaryDetails.fieldExpenses}
                />
                <SalaryDetailComponent title="PRI" description={`₹ ${salaryDetails.pri} (From Table)`} value={salaryDetails.pri} />
                <SalaryDetailComponent
                    title="D.A."
                    description={`${workDays} Days x ₹ ${appliedSalarySlab.da} (From Table)`}
                    value={salaryDetails.da}
                />
                <SalaryDetailComponent
                    title="T.A."
                    description={`${workDays} Days x ₹ ${appliedSalarySlab.ta} (From Table)`}
                    value={salaryDetails.ta}
                />
                <SalaryDetailComponent
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${unitsSold} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={salaryDetails.petrol}
                />
                <SalaryDetailComponent
                    title="Incentive."
                    description={` ₹ ${appliedSalarySlab.incentive} (From Table)`}
                    value={salaryDetails.incentive}
                />
                <Divider />
                <SalaryDetailComponent title="Deduction" description={` ₹ ${salaryDetails.deduction}`} value={salaryDetails.deduction} />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={salaryDetails.netAmount} />
                <Divider />
            </Stack>

            <TextField
                label="Remarks"
                fullWidth
                size="small"
                value={salaryDetails.remarks}
                sx={{ marginTop: 2 }}
                multiline
                onChange={(e) => {
                    SetSalaryDetails((prev) => ({
                        ...prev,
                        remarks: e.target.value
                    }));
                }}
            />

            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? (
                <> */}
            <Button
                variant="outlined"
                onClick={() => {
                    handleCalculate();
                }}
            >
                Calculate
            </Button>
            <LoadingButton
                loading={submitting}
                variant="contained"
                onClick={() => {
                    handleCalculate();
                    seSaveSalary();
                    setEditMode(false);
                }}
                sx={{ marginLeft: 2 }}
            >
                Save
            </LoadingButton>
            {/* </>
            ) : (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    Edit
                </Button>
            )} */}
        </Card>
    );
};

export default SECard;
