//import React from 'react';

import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useSalesRecordService = () => {
    const getSalesRecord = async (skip, take, location) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords?skip=${skip}&take=${take}&location=${location}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesRecordById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getUnitsSold = async (employeeId, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords/units-sold?employeeId=${employeeId}&month=${month}&year=${year}`);

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addSalesRecord = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/SalesRecords`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateSalesRecord = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SalesRecords?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteSalesRecord = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/SalesRecords?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getSalesRecord,
        addSalesRecord,
        updateSalesRecord,
        deleteSalesRecord,
        getSalesRecordById,
        getUnitsSold
    };
};

export default useSalesRecordService;
