import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useSalariesService = () => {
    const getSalariesByUserId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Salaries/by-user-id?userId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalariesById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Salaries/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getUnitsSold = async (employeeId, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords/units-sold?employeeId=${employeeId}&month=${month}&year=${year}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getAllSalaries = async (role, location, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/Salaries/filter?role=${role}&location=${location}&month=${month}&year=${year}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addSalaries = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Salaries`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateSalaries = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Salaries?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteSalaries = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Salaries?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getSalariesByUserId,
        getSalariesById,
        addSalaries,
        updateSalaries,
        deleteSalaries,
        getAllSalaries
    };
};

export default useSalariesService;
